import { ChangeEvent } from 'react';

import { useSanitizedInput } from '../../hooks/useSanitizedInput/useSanitizedInput';

export type SanitizableProps<T = string> = {
  value?: T;
  onChange?: ( event: React.ChangeEvent<HTMLInputElement> ) => void;
};

export function withSanitization<P extends SanitizableProps<T>, T = string>(
  WrappedComponent: React.ComponentType<P>,
) {
  return (
    props: Omit<P, keyof SanitizableProps<T>> & SanitizableProps<T>,
  ): JSX.Element => {
    const { value: initialValue, onChange, ...rest } = props;

    const { value, onChange: handleSanitizedChange } = useSanitizedInput(
      ( initialValue ?? '' ) as string,
    );

    const handleChange = ( event: ChangeEvent<HTMLInputElement> ): void => {
      handleSanitizedChange( event );
      if ( onChange ) {
        onChange( event );
      }
    };

    return (
      <WrappedComponent
        {...( rest as P )}
        value={value as T}
        onChange={handleChange}
      />
    );
  };
}
