import './AuthContextConfig';
import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { client } from '../../apollo/ApolloConfig';
import {
  kanbanJobTitleId, kanbanSkillType, loadingWizard, userRoleVar,
} from '../../cache/cache';
import { majorIdVar } from '../../cache/common/majorIdReactiveVar';
import { renderNotification } from '../../cache/learner/homeReactiveVars';
import { kanbanDataVar } from '../../cache/learner/kanbanReactiveVar';
import CircularLoaderComponent from '../../components/generics/CircularLoaderComponent/CircularLoaderComponent';
import useKanbanDataHook from '../../hooks/kanbanDataHook/useKanbanDataHook';
import useSetDataFromLocalStorage from '../../hooks/useSetDataFromLocalStorage/useSetDataFromLocalStorage';
import { Mixpanel } from '../../mixpanel/mixpanel';
import { useQueryGetFeatureFlags } from '../../operations/query/public/features/useGetFeatureFlags';
import { IFeatureWithValue, useQueryGetFeaturesWithValue } from '../../operations/query/public/features/useGetFeaturesWithValues';
import { IGetLearnerEntireProfile } from '../../operations/query/students/useGetLearnerEntireProfile/useGetLearnerEntireProfile';
import {
  PATH_AUTH,
  PATH_DASHBOARD, PATH_DASHBOARD_STUDENT, PATH_WIZARD,
} from '../../routes/paths';
import {
  getCookie, removeCookie, setCookie,
} from '../../utils/cookiesUtil';
import { handleCaseAuth } from '../../utils/handleCaseAuth';
import { tokenDecode } from '../../utils/jwtUtils';
import { ILoginUserSuccessResponse, ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';
import { FeatureFlagsContext } from '../FeatureFlagsContext';
import { IUserAuth } from '../reducer/userAuthReducer';

interface IAuthContextProviderProps {
  children: ReactNode;
  dispatch: React.Dispatch<{
    type: string;
    initialValue: string[];
    features?: IFeatureWithValue[];
  }>;
}

const userAuthInitialState : IUserAuth = {
  auth: {
    refreshToken: '',
    expiresIn: 0,
    remember: false,
    username: '',
    uid: '',
    displayName: '',
    onBoardingCompleted: false,
    email: '',
    student: {
      id: '',
      email: '',
      name: '',
      lastName: '',
      studentType: '',
    },
    studentInstitution: {
      institutionId: '',
      institutionName: '',
      otherInstitutionName: '',
    },
    userRole: '',
    jwtToken: '',
    features: [],
    preferredLanguage: '',
  },
  // eslint-disable-next-line
  logout: async () => new Promise((resolve) => resolve()),
  // eslint-disable-next-line
  setToken: ( _: ILoginUserSuccessResponse, expiresIn: number, jwtToken?: string ) => {},
};

const AuthContext = createContext<IUserAuth>( userAuthInitialState );

const AuthContextProvider = ({ children, dispatch }: IAuthContextProviderProps ): JSX.Element | null => {
  const { t } = useTranslation( 'AuthContext' );
  const location = useLocation();
  const navigate = useNavigate();
  const [auth, setAuth] = useState<ILoginUserSuccessResponse | undefined | null>( undefined );
  const [learnerEntireProfile, setLearnerEntireProfile] = useState<IGetLearnerEntireProfile | undefined>( undefined );
  const [learnerProfileLoading, setLearnerProfileLoading] = useState( false );
  const { features, setFeatures } = useContext( FeatureFlagsContext );
  const { i18n } = useTranslation();
  const { data: featuresData, loading } = useQueryGetFeatureFlags( false );
  const { data: featuresDataWithValues } = useQueryGetFeaturesWithValue( false );
  const { operations } = useKanbanDataHook( kanbanDataVar, kanbanSkillType, kanbanJobTitleId );
  const { operations: localStorageOperations } = useSetDataFromLocalStorage();

  const commonAuthLogout = ( clearSessionStorage = false ): void => {
    removeCookie( 'token' );
    removeCookie( 'preferredLanguage' );
    if ( clearSessionStorage ) {
      sessionStorage.clear();
    }
    setAuth( null );
  };

  const getStudentPath = (
    isCompleteStudentInfo: boolean,
  ): string => ( isCompleteStudentInfo ? PATH_DASHBOARD_STUDENT.admin.pageHome : PATH_WIZARD.root );

  const handledAuth = () : void => {
    const token = getCookie( 'token' );

    const user = token ? tokenDecode( token ) : undefined;
    const caseToEvaluate = handleCaseAuth({ user, token, features });

    switch ( caseToEvaluate ) {
      case 'jwtTokenAndTokenAndNotExpiredAndnotEmbebedInIframe':

        dispatch({ type: 'addFeatures', initialValue: user?.features ?? [] });
        setAuth( user );
        if ( location.pathname === '/' ) {
          if ( user?.userRole === ROLE_ENUM.ADMIN ) {
            navigate( PATH_DASHBOARD.admin.pageInsights );
          } else {
            navigate( getStudentPath( !!user?.onBoardingCompleted ));
          }
        }
        break;

      case 'embebedInIframeAndPlatform':
        commonAuthLogout( true );
        navigate( PATH_AUTH.login );
        break;

      case 'embebedInIframe':
        commonAuthLogout( true );
        navigate( PATH_AUTH.redirect );
        break;

      case 'tokenAndExpiredTokenAndredirectLogoutUrl':
        commonAuthLogout();
        window.location.href = user?.redirectLogoutUrl ?? window.location.href;
        break;

      default:
        commonAuthLogout();
    }
  };

  useEffect(() => {
    handledAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ( auth ) {
      dispatch({ type: 'addFeatures', initialValue: auth?.features ?? [] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const logout = async (): Promise<void> => {
    Mixpanel.reset();

    setAuth( null );
    userRoleVar( '' );
    localStorageOperations.deleteLocalStorage();
    loadingWizard( false );
    renderNotification({
      newNotifications: [],
      beforeNotifications: [],
    });
    majorIdVar( undefined );
    operations.setKanbanData([]);
    await client.clearStore();
  };

  const setToken = ( token: ILoginUserSuccessResponse, expiresIn: number, jwtToken?: string ) : void => {
    setAuth({
      ...token,
      jwtToken: token.jwtToken ?? jwtToken ?? '',
    });

    if ( jwtToken ) {
      setCookie( 'token', jwtToken );
    }
  };

  useEffect(() => {
    const lang = getCookie( 'preferredLanguage' ) ?? features?.defaultLanguage?.language ?? 'en';

    i18n.changeLanguage( lang );
  }, [features, i18n]);

  useEffect(() => {
    const token = getCookie( 'token' );

    const userAuth = token ? tokenDecode( token ) : undefined;

    userRoleVar( userAuth?.userRole ?? '' );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ( featuresData ) {
      setFeatures( featuresData.features );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresData]);

  useEffect(() => {
    if ( featuresData && featuresDataWithValues ) {
      dispatch({
        type: 'addFeaturesWithValues',
        initialValue: [],
        features: featuresDataWithValues.getFeaturesWithValue,
      });
    }
  }, [featuresDataWithValues, featuresData, dispatch, auth]);

  const authData: any = useMemo(
    () => ({
      auth,
      logout,
      setToken,
      learnerEntireProfile,
      setLearnerEntireProfile,
      learnerProfileLoading,
      setLearnerProfileLoading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, learnerEntireProfile, learnerProfileLoading],
  );

  if ( loading ) {
    return (
      <>
        { location.pathname.includes( 'auth' ) && (
          <CircularLoaderComponent
            isLoading={loading}
            color="light"
            text={t( 'LOADER_TEXT' )}
            textColor="white"
          />
        )}
      </>
    );
  }

  return (
    <AuthContext.Provider value={authData}>
      {children}
    </AuthContext.Provider>
  );
};

export {
  AuthContext,
  AuthContextProvider,
};
