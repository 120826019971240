import { styleConfigs } from '../components/resources/styleConfigs';
import icons from '../constants/icons';

export type PositionLogo = 'horizontal' | 'horizontal-large';

export interface ILogoDetails {
  url: string;
  position?: PositionLogo;
}

export interface IGetLogo {
  shared?: ILogoDetails;
  specific?: {
    wizard?: ILogoDetails;
    home?: ILogoDetails;
  };
}

export interface ICustomLogo {
  isCustom: boolean,
  position?: PositionLogo
}

export const getLogoFromFeatures = ( customLogo?: IGetLogo, isWizard?: boolean ): ILogoDetails => {
  const getDefaultLogo = (): ILogoDetails =>
    ( isWizard
      ? { url: icons.LEARNER_LOGO_HORIZONTAL, position: 'horizontal' }
      : { url: icons.LEARNER_LOGO });

  if ( !customLogo ) {
    return getDefaultLogo();
  }

  const specificLogo = isWizard
    ? customLogo.specific?.wizard
    : customLogo.specific?.home;

  if ( specificLogo ) {
    return specificLogo;
  }

  const sharedLogo = customLogo.shared;

  if ( sharedLogo?.url === icons.DEFAULT_ICON ) {
    return getDefaultLogo();
  }

  return sharedLogo || getDefaultLogo();
};

export const getWithLogo = ({ isCustom, position }: ICustomLogo ): number => {
  if ( isCustom ) {
    switch ( position ) {
      case 'horizontal':
        return styleConfigs.dimensions.width200;

      case 'horizontal-large':
        return styleConfigs.dimensions.width300;

      default:
        return styleConfigs.dimensions.width143;
    }
  }

  return styleConfigs.dimensions.width200;
};
