export const defaultMergeFieldPolicy = <T>( existing: T[], incoming: T[], { args }: Record<string, any> ): T[] => {
  const merged = existing ? existing.slice( 0 ) : [];
  const offset = args?.offset || 0;

  for ( let i = 0; i < incoming.length; ++i ) {
    merged[offset + i] = incoming[i];
  }

  return merged;
};
