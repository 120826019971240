import environmentVariables from '../../appVariables';
import { FeaturesEnum } from '../../constants/enums/FeaturesEnum';
import { IFeatureWithValue } from '../../operations/query/public/features/useGetFeaturesWithValues';
import { toCamelCaseFromSnakeCase } from '../../utils/formatString';
import { ICustomFavicon } from '../../utils/getCustomFavicon';
import { ICustomRedirectUrl } from '../../utils/getCustomRedirectUrl';
import { IGetLogo } from '../../utils/getLogoFrom';
import { ILPCoursesNumberProviders } from '../../utils/hideCoursesNumberUtils';
import { IDefaultLanguage } from '../../utils/languageUtils';

export interface IDefaultStudentInstitution {
  id: string;
}

export interface IDefaultCountry {
  country: string;
}

export interface IEmbededLogin{
  url: string;
}

export interface IDefaultLevelOfStudy {
  type: string;
}

export interface IFeatures {
  sso?: boolean;
  registerUser?: boolean;
  customLogo?: IGetLogo;
  customFavicon?: ICustomFavicon;
  learner?: {
    hideLearnerHelp: boolean;
    defaultStudentInstitution?: IDefaultStudentInstitution;
    hideProfileEditButton: boolean;
    hideLearningPathwaysScreen: boolean;
    showHobbies: boolean;
    institutionWizardQuestion: boolean;
    typeOfUserWizardQuestion: boolean;
    employabilityStatusWizardQuestion: boolean;
    defaultLevelOfStudy?: IDefaultLevelOfStudy;
    learningPathShowInOccupations?: boolean;
    academicProgramEnabled: boolean;
    showCourseRecommendationsMessage: boolean;
    showFooter?: boolean;
    showInfoSkillsSection?: boolean;
    showInfoLearningPathSection?: boolean;
    showInfoWizard?: boolean;
    showCareerDiscovery?: boolean;
    showBadgeSkillValidated?: boolean;
  };
  advisor?: {
    hideCareerTrendsScreen?: boolean;
    hideStudentList?: boolean;
    hideSkillInsights?: boolean;
    hideStudentOverview?: boolean;
    hideStudentAdvising?: boolean;
    hideContactUs?: boolean;
    hideFAQ?: boolean;
    showStudentTypeFilter?: boolean;
  }
  defaultLanguage?: IDefaultLanguage;
  embededLogin?: IEmbededLogin;
  embededInIframe?: boolean;
  defaultCountry?: IDefaultCountry;
  specialzedMajors?: boolean;
  occupationsLimitedEnabled?: boolean;
  samlGriky: boolean;
  samlUVA: boolean;
  iframeAndPlatform: boolean;
  qsInstance?: boolean;
  showLpCoursesNumberProviders?: ILPCoursesNumberProviders;
  customRedirectUrl?: ICustomRedirectUrl;
  whiteLabel?: boolean;
  showOffice365?: boolean;
  singleTenantSSO?: boolean;
}

export interface IFeatureFlagsReducer {
  features: IFeatures
  setFeatures: ( initialValue: string[]) => void
  addFeaturesWithValues?: ( features: IFeatureWithValue[]) => void
}

export interface IIFeatureFlagsReducerAction {
  type: string;
  initialValue: string[];
  features?: IFeatureWithValue[];
}

export const featuresFlagReducerState: IFeatureFlagsReducer = {
  features: {
    defaultLanguage: {
      language: 'en',
    },
    embededLogin: undefined,
    customFavicon: undefined,
    embededInIframe: environmentVariables?.embededInIframe === 'true',
    samlGriky: environmentVariables?.reactAppSamlGriky === 'true',
    samlUVA: environmentVariables?.reactAppSamlUVA === 'true',
    iframeAndPlatform: environmentVariables?.iframeAndPlatform === 'true',
    showLpCoursesNumberProviders: {
      providers: [],
    },
    customRedirectUrl: {
      url: '',
    },
    showOffice365: false,
    singleTenantSSO: false,
  },
  setFeatures: () => null,
};

const featureFlagReducer = (
  state: IFeatureFlagsReducer,
  action: IIFeatureFlagsReducerAction,
): IFeatureFlagsReducer => {
  const defaultState = state || featuresFlagReducerState;
  const embededInIframe = environmentVariables?.embededInIframe === 'true';
  const iframeAndPlatform = environmentVariables?.iframeAndPlatform === 'true';

  const findFeature = ( feature: string ): boolean =>
    !!action.initialValue.find(( availableFeature ) => availableFeature === feature );

  const hasFeature = ( features: string[], feature: string ): boolean =>
    features.some(( item ) => item.includes( feature ));

  switch ( action.type ) {
    case 'addFeatures':

      return {
        ...defaultState,
        features: {
          sso: findFeature( FeaturesEnum.sso ),
          registerUser: findFeature( FeaturesEnum.registerUser ),
          learner: {
            hideLearnerHelp: findFeature( FeaturesEnum.hideLearnerHelp ),
            hideProfileEditButton: findFeature( FeaturesEnum.learnerHideProfileEditButton ),
            hideLearningPathwaysScreen: findFeature( FeaturesEnum.hideLearningPathWaysScreen ),
            showHobbies: findFeature( FeaturesEnum.showHobbies ),
            institutionWizardQuestion: findFeature( FeaturesEnum.institutionWizardQuestion ),
            typeOfUserWizardQuestion: findFeature( FeaturesEnum.typeOfUserWizardQuestion ),
            employabilityStatusWizardQuestion: findFeature( FeaturesEnum.showEmployabilityStatus ),
            learningPathShowInOccupations: findFeature( FeaturesEnum.learningPathShowInOccupations ),
            academicProgramEnabled: findFeature( FeaturesEnum.academicProgramEnabled ),
            showCourseRecommendationsMessage: findFeature( FeaturesEnum.showCourseRecommendationsMessage ),
            showFooter: findFeature( FeaturesEnum.showFooter ),
            showInfoSkillsSection: findFeature( FeaturesEnum.showInfoSkillsSection ),
            showInfoLearningPathSection: findFeature( FeaturesEnum.showInfoLearningPathSection ),
            showInfoWizard: findFeature( FeaturesEnum.showInfoWizard ),
            showCareerDiscovery: findFeature( FeaturesEnum.showCareerDiscovery ),
            showBadgeSkillValidated: findFeature( FeaturesEnum.showBadgeSkillValidated ),
          },
          advisor: {
            hideCareerTrendsScreen: findFeature( FeaturesEnum.hideCareerTrendsScreen ),
            hideStudentList: findFeature( FeaturesEnum.hideAdvisorStudentList ),
            hideSkillInsights: findFeature( FeaturesEnum.hideAdvisorSkillInsights ),
            hideStudentAdvising: findFeature( FeaturesEnum.hideAdvisorStudentAdvising ),
            hideStudentOverview: findFeature( FeaturesEnum.hideAdvisorStudentOverview ),
            hideContactUs: findFeature( FeaturesEnum.hideAdvisorContactUs ),
            hideFAQ: findFeature( FeaturesEnum.hideAdvisorFAQ ),
            showStudentTypeFilter: findFeature( FeaturesEnum.showStudentTypeFilter ),
          },
          embededInIframe: !embededInIframe ? findFeature( FeaturesEnum.embededInIframe ) : embededInIframe,
          specialzedMajors: findFeature( FeaturesEnum.specializedMajors ),
          occupationsLimitedEnabled: findFeature( FeaturesEnum.occupationsLimitedEnabled ),
          samlGriky: findFeature( FeaturesEnum.samlGriky ),
          samlUVA: findFeature( FeaturesEnum.samlUVA ),
          iframeAndPlatform: !iframeAndPlatform ? findFeature( FeaturesEnum.iframeAndPlatform ) : iframeAndPlatform,
          qsInstance: findFeature( FeaturesEnum.qsInstance ),
          whiteLabel: findFeature( FeaturesEnum.whiteLabel ),
          showOffice365: hasFeature(
            action.initialValue,
            FeaturesEnum.showOffice365,
          ),
          singleTenantSSO: hasFeature(
            action.initialValue,
            FeaturesEnum.singleTenantSSO,
          ),
        },
      };

    case 'addFeaturesWithValues': {
      const featuresMapWithValues = action?.features?.reduce(( map, feature ) => {
        const normalizedKey = toCamelCaseFromSnakeCase( feature.name );

        return {
          ...map,
          [normalizedKey]: JSON.parse( feature.value ),
        };
      }, {} as Record<string, string> );

      if ( featuresMapWithValues ) {
        return {
          ...defaultState,
          features: {
            ...defaultState.features,
            ...featuresMapWithValues,
          },
        };
      }

      return defaultState;
    }

    default:
      return state;
  }
};

export default featureFlagReducer;
