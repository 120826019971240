export interface ICustomRedirectUrl {
  url: string;
}

export const getCustomRedirectUrl = ({ url }: ICustomRedirectUrl ): string => {
  if ( !url ) {
    return '';
  }

  return url;
};
