import DOMPurify from 'isomorphic-dompurify';
import { useState, ChangeEvent } from 'react';

interface IUseSanitizedInput {
  value: string;
  onChange: ( event: ChangeEvent<HTMLInputElement> ) => void;
}

export function useSanitizedInput( initialValue = '' ): IUseSanitizedInput {
  const [value, setValue] = useState( initialValue );

  const manualSanitize = ( sanitizedInput: string ): string => sanitizedInput.replace( /javascript:/gi, '' ).replace( /on\w+=/gi, '' );

  const handleChange = ( event: ChangeEvent<HTMLInputElement> ): void => {
    let sanitizedValue;

    if ( event?.target.name?.includes( 'password' )) {
      sanitizedValue = manualSanitize( event.target.value );
    } else {
      sanitizedValue = DOMPurify.sanitize( event?.target.value );
    }
    setValue( sanitizedValue );
  };

  return {
    value,
    onChange: handleChange,
  };
}
