import {
  gql, QueryResult, QueryTuple, useLazyQuery, useQuery,
} from '@apollo/client';

import { IJobTitlesInstitution } from '../../../cache/learner/jobTitlesInstitutionReactiveVar';
import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_JOB_TITLES_INSTITUTION = gql`
  query GetJobTitlesInstitution ($institutionId: NonEmptyString!, $jobTitlesId: [NonEmptyString]) {
    getJobTitlesInstitution ( institutionId: $institutionId, jobTitlesId: $jobTitlesId ) {
      institutionId
      jobTitleId
  }
}`;

export interface IGetJobTitlesInstitutionParams {
  institutionId: string;
  jobTitlesId?: string[];
  fromCache?: boolean;
}

export interface IGetJobTitlesInstitutionResponse {
  getJobTitlesInstitution?: IJobTitlesInstitution[]
}

export const useLazyGetJobTitlesInstitution = ( fromCache?: boolean ):
QueryTuple<IGetJobTitlesInstitutionResponse, IGetJobTitlesInstitutionParams> =>
  useLazyQuery<IGetJobTitlesInstitutionResponse, IGetJobTitlesInstitutionParams>(
    GET_JOB_TITLES_INSTITUTION,
    { ...getPolices( fromCache ) },
  );

export const useQueryGetJobTitlesInstitution = ({
  institutionId,
  jobTitlesId,
  fromCache,
}: IGetJobTitlesInstitutionParams ):
QueryResult<IGetJobTitlesInstitutionResponse, IGetJobTitlesInstitutionParams> =>
  useQuery<IGetJobTitlesInstitutionResponse, IGetJobTitlesInstitutionParams>(
    GET_JOB_TITLES_INSTITUTION, {
      variables: { institutionId, jobTitlesId },
      ...getPolices( fromCache ),
    },
  );
