import './RoleDividerModalConfig';
import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import {
  TitleStyled,
  SubtitleStyled,
  IconsContainer,
  CardContainerStyled,
  CardTitleStyled,
  RoleButtonStyled,
  ButtonsContainer,
  MainContainerStyled,
  CancelLink,
} from './styles/RoleDividerModalStyles';
import icons from '../../../constants/icons';
import { USER_ROLES } from '../../../constants/roles';
import { AuthContext } from '../../../contexts/AuthContext/AuthContext';
import { FeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import { ModalContext } from '../../../contexts/ModalContext';
import useNotificationsHook from '../../../hooks/notificationsHook/useNotificationsHook';
import { useRegistrationsHook } from '../../../hooks/useRegistrationsHook/useRegistrationsHook';
import palette from '../../../theme/learnerPalette';
import { getCookie } from '../../../utils/cookiesUtil';
import { styleConfigs } from '../../resources/styleConfigs';
import SvgIconStyle from '../../template-minimal/SvgIconStyle';
import NewModalLayoutComponent from '../ModalComponent/NewModalLayoutComponent';

const RoleDividerModal: React.FC = () => {
  const { modalClose } = useContext( ModalContext );
  const { logout } = useContext( AuthContext );
  const { t } = useTranslation( 'RoleDividerModal' );
  const { signInWithMultipleRoles } = useRegistrationsHook();
  const { features } = useContext( FeatureFlagsContext );
  const { operations: notificationOps } = useNotificationsHook();

  const [selectedCard, setSelectedCard] = useState<string | undefined>( undefined );

  const handleCardClick = ( card: string ): void => {
    setSelectedCard( card );
  };

  const handleCloseButton = async (): Promise<void> => {
    await logout();
    modalClose();
  };

  const handleSubmitButton = (): void => {
    let allTrue = false;

    if ( features.advisor && selectedCard === USER_ROLES.ADMIN ) {
      allTrue = Object.values( features.advisor ).every(( value ) => value === true );
      modalClose();
      if ( allTrue ) {
        notificationOps.addNotification( 'danger', t( 'DESCRIPTION_ERROR' ), t( 'TITLE_ERROR' ));

        return;
      }
    }
    const jwtToken = getCookie( 'token' );

    modalClose();
    signInWithMultipleRoles({ jwtToken }, selectedCard );
  };

  return (
    <NewModalLayoutComponent titleHeader={<></>}>
      <MainContainerStyled isMobile={isMobile}>
        <TitleStyled>{t( 'TITLE' )}</TitleStyled>
        <SubtitleStyled isMobile={isMobile}>{t( 'SUBTITLE' )}</SubtitleStyled>
        <IconsContainer>
          <CardContainerStyled
            isSelected={selectedCard === USER_ROLES.LEARNER}
            onClick={() => handleCardClick( USER_ROLES.LEARNER )}
          >
            <SvgIconStyle
              src={icons.LEARNER_ROLE_ICON}
              width={isMobile ? styleConfigs.dimensions.width51 : styleConfigs.dimensions.width70}
              height={isMobile ? styleConfigs.dimensions.width51 : styleConfigs.dimensions.height70}
              sx={{ bgcolor: palette.light.text.mistBlue }}
            />
            <CardTitleStyled>{t( 'LEARNER_USER' )}</CardTitleStyled>
          </CardContainerStyled>
          <CardContainerStyled
            isSelected={selectedCard === USER_ROLES.ADMIN}
            onClick={() => handleCardClick( USER_ROLES.ADMIN )}
          >
            <SvgIconStyle
              src={icons.ADVISOR_ROLE_ICON}
              width={isMobile ? styleConfigs.dimensions.width51 : styleConfigs.dimensions.width70}
              height={isMobile ? styleConfigs.dimensions.width51 : styleConfigs.dimensions.height70}
              sx={{ bgcolor: palette.light.text.mistBlue }}
            />
            <CardTitleStyled>{t( 'ADVISOR_USER' )}</CardTitleStyled>
          </CardContainerStyled>
        </IconsContainer>
        <ButtonsContainer>
          <RoleButtonStyled
            onClick={handleSubmitButton}
            disabled={!selectedCard}
            color="secondary"
          >
            {selectedCard ? t( 'ENABLED_BUTTON' ) : t( 'DISABLED_BUTTON' )}
          </RoleButtonStyled>
          <CancelLink
            onClick={handleCloseButton}
          >
            {t( 'CANCEL' )}
          </CancelLink>
        </ButtonsContainer>
      </MainContainerStyled>
    </NewModalLayoutComponent>
  );
};

export default RoleDividerModal;
