import './AccountPopoverConfig';
import { Icon } from '@iconify/react';
import {
  Avatar, Box, Divider, MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import AccountBrief from './AccountPopOverComponents/AccountBrief';
import AccountInfo from './AccountPopOverComponents/AccountInfo';
import { ListItemIconStyled, ListItemTextStyled } from './styles/DashboardNavbarStyles';
import MenuPopover from '../../components/template-minimal/MenuPopoverComponent/MenuPopover';
import SvgIconStyle from '../../components/template-minimal/SvgIconStyle';
import icons from '../../constants/icons';
import userAuth from '../../hooks/userAuth';
import { Mixpanel } from '../../mixpanel/mixpanel';
import { MixpanelComponentsNames } from '../../mixpanel/MixpanelEvents/mixpanelComponentNames';
import { MixpanelEventsEnum } from '../../mixpanel/MixpanelEvents/mixpanelEventsInterface';
import { getMixpanelName } from '../../utils/getMixpanelName';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';
import { ExpandMoreStyled, MIconButtonStyled } from '../dashboard-student/styles/AccountPopoverStyles';

type IAccountPopoverProps = {
  openChangePasswordDialog: VoidFunction;
}

export default function AccountPopover( props: IAccountPopoverProps ): JSX.Element {
  const { logout, auth } = userAuth();
  const navigate = useNavigate();
  const anchorRef = useRef( null );
  const [open, setOpen] = useState( false );
  const { pathname, search } = useLocation();
  const { openChangePasswordDialog } = props;
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const theme = useTheme();
  const [fromKeyboard, setFromKeyboard] = useState( 0 );
  const { t } = useTranslation( 'AccountPopover' );

  const getIcon = ( icon: string ): JSX.Element => (
    <SvgIconStyle src={icon} sx={{ width: '100%', height: '100%' }} />
  );
  const ICONS = {
    password: getIcon( icons.PASSWORD_ICON ),
    logout: getIcon( icons.LOGOUT_ICON ),
  };

  const MENU_OPTIONS = [
    { label: 'CHANGE_PASSWORD', icon: ICONS.password, linkTo: '/' },
    { label: 'LOGOUT', icon: ICONS.logout, linkTo: '#' },
  ];

  const handleOpen = (): void => {
    setFromKeyboard( 0 );
    setOpen( true );
    Mixpanel.track({
      action: MixpanelEventsEnum.ADVISOR_PROFILE_POPOVER,
      data: {
        screen: getMixpanelName( pathname.concat( search )),
        component: MixpanelComponentsNames.ADVISOR_ACCOUNT_POPOVER,
        action: 'click',
      },
    }, isLearner );
  };
  const handleClose = async ( label?: string ): Promise<void> => {
    if ( label ) {
      if ( label.toLowerCase() === 'change_password' ) {
        openChangePasswordDialog();
        Mixpanel.track({
          action: MixpanelEventsEnum.CHANGE_PASSWORD,
          data: {
            screen: getMixpanelName( pathname.concat( search )),
            component: MixpanelComponentsNames.ADVISOR_ACCOUNT_POPOVER,
            action: 'click',
          },
        }, isLearner );
      }
      if ( label.toLowerCase() === 'logout' ) {
        await logout();
        navigate( '/' );
        Mixpanel.track({
          action: MixpanelEventsEnum.LOGOUT,
          data: {
            screen: getMixpanelName( pathname.concat( search )),
            component: MixpanelComponentsNames.ADVISOR_ACCOUNT_POPOVER,
            action: 'click',
          },
        }, isLearner );
      }
    }
    setOpen( false );
  };

  const handleKeyDownButton = ( e: React.KeyboardEvent<HTMLButtonElement> ):void => {
    const keyPressed = e.key;

    switch ( keyPressed ) {
      case 'ArrowRight':
        setFromKeyboard( 1 );
        break;

      case 'ArrowLeft':
        setFromKeyboard( 1 );
        break;

      case 'End':
        setFromKeyboard( 1 );
        break;

      case 'Home':
        setFromKeyboard( 1 );
        break;

      case 'Tab':
        setFromKeyboard( 1 );
        break;

      default:
        setFromKeyboard( 0 );

        break;
    }
  };

  return (
    <>
      <MIconButtonStyled
        ref={anchorRef}
        onClick={handleOpen}
        onKeyDown={handleKeyDownButton}
        open={open ? 1 : 0}
        theme={theme}
        islearner={isLearner ? 1 : 0}
        fromkeyboard={fromKeyboard}
      >
        <Avatar
          alt="User"
          src={icons.DEFAULT_UNIVERSITY_AVATAR}
          sx={{
            borderRadius: '10px', height: '35px', width: '35px',
          }}
        />
        <AccountBrief />
        <ExpandMoreStyled />
      </MIconButtonStyled>

      <MenuPopover
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
        width={330}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <AccountInfo />
        </Box>
        {MENU_OPTIONS.map(( option ) => (
          <div key={option.label}>
            <Divider variant="middle" />
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={( event: any ) => {
                event.preventDefault();
                handleClose( option.label );
              }}
              sx={{
                typography: 'body2', py: 2.5, px: 2.5,
              }}
            >
              <Box
                component={Icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />
              <ListItemIconStyled>
                {option.icon}
              </ListItemIconStyled>
              <ListItemTextStyled>
                {t( option.label )}
              </ListItemTextStyled>

            </MenuItem>
          </div>
        ))}
      </MenuPopover>
    </>
  );
}
