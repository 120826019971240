import Cookies from 'js-cookie';

import { cookieExpires, cookieSameSite, ICookies } from '../config';

interface ICookieOptions {
  expires?: number;
  sameSite?: 'strict' | 'lax' | 'none';
}

export const setCookie = ( cookieName: ICookies, value: string, options?: ICookieOptions ): void => {
  Cookies.set( cookieName, value, {
    expires: options?.expires ?? cookieExpires,
    sameSite: options?.sameSite ?? cookieSameSite,
    secure: true,
  });
};

export const getCookie = <T = string>( cookieName: ICookies ): T|undefined => {
  const data = Cookies.get( cookieName );

  if ( !data ) {
    return undefined;
  }

  try {
    return JSON.parse( data );
  } catch {
    return data as T;
  }
};

export const removeCookie = ( cookieName: ICookies ): void => {
  Cookies.remove( cookieName, {
    expires: cookieExpires,
    sameSite: cookieSameSite,
    secure: true,
  });
};
export const removeAllCookies = (): void => {
  const cookies = Cookies.get();

  Object.keys( cookies ).forEach(( cookieName ) => {
    Cookies.remove( cookieName, {
      expires: cookieExpires,
      sameSite: cookieSameSite,
      secure: true,
    });
  });
};
