import './useRegistrationHookConfig';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { loadingSSO, loadingWizard, userRoleVar } from '../../cache/cache';
import RoleDividerModal from '../../components/generics/RoleDividerModal/RoleDividerModal';
import { styleConfigs } from '../../components/resources/styleConfigs';
import { MULTIPLE_ROLES_FLAG } from '../../constants/flags';
import { USER_ROLES } from '../../constants/roles';
import { ModalContext } from '../../contexts/ModalContext';
import { Mixpanel } from '../../mixpanel/mixpanel';
import { MixpanelComponentsNames } from '../../mixpanel/MixpanelEvents/mixpanelComponentNames';
import { MixpanelEventsEnum } from '../../mixpanel/MixpanelEvents/mixpanelEventsInterface';
import { useMutationChangePreferredLanguage } from '../../operations/mutations/loginUser/changePreferredLanguage';
import { useLoginCustomSSO } from '../../operations/mutations/public/loginCustomSSO/loginCustomSSO';
import { useMutationSignIn } from '../../operations/mutations/public/useSignIn/useSignIn';
import { useMutationSignInMultipleRoles } from '../../operations/mutations/public/useSignIn/useSignInMultipleRoles';
import { useMutationSignup } from '../../operations/mutations/public/useSignup/useSignup';
import { useMutationSignupGoogle } from '../../operations/mutations/public/useSignupGoogle/useSignupGoogle';
import {
  PATH_DASHBOARD, PATH_DASHBOARD_STUDENT, PATH_WIZARD,
} from '../../routes/paths';
import { setCookie, getCookie } from '../../utils/cookiesUtil';
import { getMixpanelName } from '../../utils/getMixpanelName';
import { tokenDecode } from '../../utils/jwtUtils';
import {
  IGoogleLoginResponse, ILoginResponse, ILoginUserSuccessResponse, ROLE_ENUM,
} from '../../views/Auth/Login/interfaces/IGoogleUser';
import userAuth from '../userAuth';

export interface IRegistrationHookResponse {
  isDisabled: boolean;
  openAlert: boolean;
  serverError: string;
  signIn: ( email: string, password: string, remember: boolean ) => Promise<void>
  setServerError: ( errorState: string ) => void
  setOpenAlert: ( alertState: boolean ) => void
  signUp: ( email: string, password: string, agree: boolean, validationCode?: string ) => Promise<void>
  signInWithGoogle: ( user: IGoogleLoginResponse ) => Promise<void>
  setUserAndRedirect: (
    remember: boolean, dataResponse: ILoginResponse ) => Promise<void>
  signInWithMultipleRoles: ( user: ILoginResponse, role?: string ) => Promise<void>
  loginCrehana: ( token: string ) => Promise<void>
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    identify: any;
  }
}

export const useRegistrationsHook = (): IRegistrationHookResponse => {
  const { auth } = userAuth();
  const [serverError, setServerErrorState] = useState( '' );
  const [openAlert, setOpenAlertState] = useState( false );
  const [isDisabled, setIsDisabled] = useState( false );
  const [changePreferedLanguageHandler] = useMutationChangePreferredLanguage();
  const { setToken } = userAuth();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [signUpMutation] = useMutationSignup();
  const [signInMutation] = useMutationSignIn();
  const [signUpGoogle] = useMutationSignupGoogle();
  const [signInMultipleRolesMutation] = useMutationSignInMultipleRoles();
  const [loginCustomSSO] = useLoginCustomSSO();
  const { modalOpen } = useContext( ModalContext );

  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const { t } = useTranslation( 'useRegistrationsHook' );

  useEffect(() => {
    const updatePreferredLanguage = async (): Promise<void> => {
      if ( auth ) {
        let finalPreferredLanguage = auth.preferredLanguage;
        const preferredLanguageCookie = getCookie( 'preferredLanguage' );

        if ( preferredLanguageCookie && preferredLanguageCookie !== auth.preferredLanguage ) {
          finalPreferredLanguage = preferredLanguageCookie;
          const { data } = await changePreferedLanguageHandler({
            variables: { preferredLanguage: preferredLanguageCookie },
          });

          const token = data?.changePreferredLanguage?.jwtToken ?? '';
          const tokenDecoded = tokenDecode( token );

          setToken( tokenDecoded, tokenDecoded.expiresIn, token );
        }
        if ( window.identify ) {
          window.identify(
            auth.email,
            finalPreferredLanguage,
            auth.studentInstitution?.institutionId,
            window.location.origin,
          );
        }
      }
    };

    updatePreferredLanguage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const manageErrors = ( customError: string ): void => {
    loadingWizard( false );
    setIsDisabled( false );
    setOpenAlertState( true );

    if ( customError.includes( 'wrong-password' ) || customError.includes( 'user-not-found' )) {
      setServerErrorState( t( 'CREDENTIALS_ERROR' ));

      return;
    }
    if ( customError.includes( 'email-already-in-use' )) {
      setServerErrorState( t( 'EMAIL_ALREADY_EXIST' ));

      return;
    }
    if ( customError.includes( 'expired-platform-validation-code' )) {
      setServerErrorState( t( 'VALIDATION_CODE_EXPIRED' ));

      return;
    }

    setServerErrorState( t( 'ERROR_MESSAGE' ));
  };

  const setServerError = ( errorState: string ): void => {
    setServerErrorState( errorState );
  };

  const setOpenAlert = ( alertState: boolean ): void => {
    setOpenAlertState( alertState );
  };

  const setUserAndRedirect = async (
    remember?: boolean, dataResponse?: ILoginResponse,
  ): Promise<void> => {
    setCookie( 'token', dataResponse?.jwtToken ?? '' );
    const response: ILoginUserSuccessResponse = tokenDecode( dataResponse?.jwtToken ?? '' );

    if ( response.userRole === MULTIPLE_ROLES_FLAG ) {
      loadingWizard( false );

      modalOpen({
        component: RoleDividerModal,
        desktopWidth: styleConfigs.dimensions.auto,
        mobileRoundedCorner: true,
        mobileMarginInline: styleConfigs.marginPadding.custom14,
      });

      return;
    }

    userRoleVar( response.userRole );

    setToken(
      {
        ...response,
        username: response.email,
        uid: response.uid,
        displayName: response.uid,
        jwtToken: dataResponse?.jwtToken ?? '',
      },
      response.expiresIn,
      dataResponse?.jwtToken ?? '',
    );

    setCookie( 'uidUser', response.uid ?? '' );
    setIsDisabled( false );

    if ( remember ) {
      setCookie(
        'remember',
        JSON.stringify({
          email: response.email,
          remember: true,
        }),
        {
          expires: 7,
        },
      );
    }

    let navigatePathname = '';

    const userRoleResponse = response.userRole === ROLE_ENUM.STUDENT;

    Mixpanel.identify( response.email, userRoleResponse );
    Mixpanel.people.set({ $email: response.email }, userRoleResponse );

    if ( userRoleResponse ) {
      Mixpanel.people.set(
        { $institution: response.studentInstitution?.institutionName, $role: ROLE_ENUM.STUDENT }, userRoleResponse,
      );
    }
    if ( response.userRole === ROLE_ENUM.ADMIN ) {
      Mixpanel.people.set(
        { $institution: response.advisor?.institutionName, $role: ROLE_ENUM.ADMIN }, userRoleResponse,
      );
    }

    switch ( response.userRole ) {
      case ROLE_ENUM.ADMIN:
        navigatePathname = PATH_DASHBOARD.admin.pageInsights;
        break;

      default:
        if ( !response.onBoardingCompleted ) {
          navigatePathname = PATH_WIZARD.root;
        } else {
          navigatePathname = PATH_DASHBOARD_STUDENT.admin.pageHome;
        }
        break;
    }

    navigate( navigatePathname );

    Mixpanel.track({
      action: MixpanelEventsEnum.LOG_IN,
      data: {
        screen: getMixpanelName( pathname.concat( search )),
        component: MixpanelComponentsNames.USE_REGISTRATIONS_HOOK,
        action: 'click',
      },
    }, isLearner );
  };

  const signIn = async ( email: string, password: string, remember: boolean ): Promise<void> => {
    try {
      setServerErrorState( '' );
      setOpenAlertState( false );
      const emailCleanUp = email.trim().toLowerCase();
      const { data } = await signInMutation({
        variables: {
          email: emailCleanUp,
          password,
          remember,
        },
      });

      if ( data?.jwtToken?.includes( 'Error' )) {
        manageErrors( data?.jwtToken );

        return;
      }

      if ( data ) {
        await setUserAndRedirect( remember, data as ILoginResponse );
      }
    } catch ( err: any ) {
      loadingWizard( false );
      setIsDisabled( false );
      setOpenAlertState( true );
      manageErrors( err?.message );
    }
  };

  const signUp = async ( email: string, password: string, agree: boolean, validationCode?: string ): Promise<void> => {
    try {
      const emailCleanUp = email.trim().toLowerCase();

      // True because SignUp is only posible for students for now
      Mixpanel.alias( emailCleanUp, true );

      await signUpMutation({
        variables: {
          email: emailCleanUp,
          password,
          privacyPolicyAccepted: agree,
          validationCode,
        },
      });
    } catch ( err: any ) {
      loadingSSO( false );
      loadingWizard( false );
      setOpenAlertState( true );
      manageErrors( err?.message );
    }

    Mixpanel.track({
      action: MixpanelEventsEnum.SIGN_UP,
      data: {
        screen: getMixpanelName( pathname.concat( search )),
        component: MixpanelComponentsNames.SIGN_UP_FORM,
        action: 'click',
      },
    }, isLearner );

    signIn( email, password, false );
  };

  const loginCrehana = async (
    token: string,
  ): Promise<void> => {
    try {
      setServerErrorState( '' );
      setOpenAlertState( false );
      const response = await loginCustomSSO({
        variables: {
          token,
          loginMethod: 'Cesa',
        },
      });

      const tokenResponse = response?.data?.loginCustomSSO;

      if ( tokenResponse?.jwtToken.includes( 'Error' )) {
        manageErrors( tokenResponse?.jwtToken );

        return;
      }

      if ( tokenResponse ) {
        await setUserAndRedirect( false, tokenResponse );
      }
    } catch ( err: any ) {
      loadingSSO( false );
      loadingWizard( false );
      setIsDisabled( false );
      setOpenAlertState( true );
      manageErrors( err?.message );
    }
  };

  const signInWithGoogle = async ( user: IGoogleLoginResponse ): Promise<void> => {
    if ( !user ) {
      return;
    }

    try {
      const response = await signUpGoogle({
        variables: {
          loginUser: {
            localId: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            accessToken: user.stsTokenManager.accessToken,
            refreshToken: user.stsTokenManager.refreshToken,
            expiresIn: user.stsTokenManager.expirationTime,
          },
        },
      });

      const dataResponse = response.data?.signInWithGoogle;

      if ( dataResponse ) {
        await setUserAndRedirect( false, dataResponse );
      }
    } catch ( err: any ) {
      loadingWizard( false );
      setIsDisabled( false );
      setOpenAlertState( true );
      manageErrors( err?.message );
    }
  };

  const signInWithMultipleRoles = async ( user: ILoginResponse, role?: string ): Promise<void> => {
    try {
      const response = await signInMultipleRolesMutation({
        variables: {
          token: user.jwtToken ?? '',
          role: role ?? USER_ROLES.LEARNER,
        },
      });

      const refreshToken = response.data?.jwtToken;

      if ( !refreshToken ) {
        loadingWizard( false );

        return;
      }
      setUserAndRedirect( false, { jwtToken: refreshToken });
    } catch ( error ) {
      loadingWizard( false );
      setIsDisabled( false );
      setOpenAlertState( true );
      manageErrors( error as string );
    }
  };

  return {
    isDisabled,
    openAlert,
    serverError,
    signIn,
    setServerError,
    setOpenAlert,
    signUp,
    signInWithGoogle,
    setUserAndRedirect,
    signInWithMultipleRoles,
    loginCrehana,
  };
};
