import {
  ApolloError,
  gql, QueryTuple, useLazyQuery,
} from '@apollo/client';

import { client } from '../../../apollo/ApolloConfig';
import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_RELATED_OCCUPATIONS = gql`
query RelatedOccupations($jobTitleIds: [NonEmptyString]!) {
  relatedOccupations(jobTitleIds: $jobTitleIds) {
    jobTitleId
    jobTitleName
    data {
      jobTitleId
      match
      name
    }
  }
}
`;

export interface IRelatedOccupationsParams {
  jobTitleIds: string[];
}

export interface IRelatedOccupations {
  jobTitleId: string;
  jobTitleName: string;
  data: {
    jobTitleId: string;
    match: number;
    name: string;
  }[];
}

export interface IRelatedOccupationsResponse {
  relatedOccupations?: IRelatedOccupations[];
}

export interface IJobRelatedOccupationsResponse {
  data: IRelatedOccupationsResponse,
  error: ApolloError | undefined
}

export const useLazyGetJobRelatedOccupations = ( fromCache?: boolean ):
QueryTuple<IRelatedOccupationsResponse, IRelatedOccupationsParams> =>
  useLazyQuery<IRelatedOccupationsResponse, IRelatedOccupationsParams>(
    GET_RELATED_OCCUPATIONS,
    { ...getPolices( fromCache ) },
  );

export const getJobRelatedOccupations = async (
  jobTitleIds: string[],
): Promise<IJobRelatedOccupationsResponse> => {
  const data = await client.query({
    query: GET_RELATED_OCCUPATIONS,
    variables: {
      jobTitleIds,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data: data.data,
    error: data.error,
  };
};
