import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../../components/resources/styleConfigs';
import ButtonWrapper from '../../../../components/wrappers/ButtonWrapper';
import DividerWrapper from '../../../../components/wrappers/DividerWrapper';
import GridWrapper from '../../../../components/wrappers/GridWrapper';
import StackWrapper from '../../../../components/wrappers/StackWrapper';
import TabsWrapper from '../../../../components/wrappers/TabsWrapper';
import TabWrapper from '../../../../components/wrappers/TabWrapper';
import useGetPaletteFromWhiteLabel from '../../../../hooks/useGetPaletteFromWhiteLabel';
import palette from '../../../../theme/learnerPalette';

export const ProfileNavbarContainerStyled = styled( GridWrapper )(() => ({
  [useTheme().breakpoints.down( 'lg' )]: {
    marginBottom: `${styleConfigs.marginPadding.custom170}px !important`,
  },
}));

export const ProfileTabsStyled = styled( TabsWrapper )(() => ({
  '& .MuiTabs-scroller ': {
    height: styleConfigs.dimensions.height36,
  },
}));

export const ProfileTabStyled = styled( TabWrapper )(() => ({
  fontWeight: styleConfigs.fonts.bold,
  fontSize: styleConfigs.fonts.fontSize20,
  color: palette.light.text.mistBlue,
  [useTheme().breakpoints.down( 'md' )]: {
    fontSize: styleConfigs.fonts.fontSize18,
    marginRight: `${styleConfigs.marginPadding.custom25}px !important`,
  },
  [useTheme().breakpoints.down( 'sm' )]: {
    fontSize: styleConfigs.fonts.fontSize14,
    marginRight: `${styleConfigs.marginPadding.custom16}px !important`,
  },
}));

export const DividerStyled = styled( DividerWrapper )(() => ({
  marginBottom: styleConfigs.marginPadding.custom32,
  [useTheme().breakpoints.down( 'sm' )]: {
    marginBottom: styleConfigs.marginPadding.custom26,
  },
}));

export const StackButtonStyled = styled( StackWrapper )(() => ({
  width: styleConfigs.dimensions.percent100,
  alignItems: 'center',
  position: 'fixed',
  bottom: styleConfigs.marginPadding.custom67,
  zIndex: 1,
}));

export const ProfileNavbarBackButton = styled( ButtonWrapper )(() => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const backgroundPrussianBlue = wlPalette?.background?.prussianBlue ?? palette.light.background.prussianBlue;

  return ({
    width: styleConfigs.dimensions.width248,
    backgroundColor: backgroundPrussianBlue,
    padding: `${styleConfigs.marginPadding.custom11}px ${styleConfigs.marginPadding.custom22}px`,
    borderRadius: styleConfigs.marginPadding.custom50,
    boxShadow: `${styleConfigs.borders.backDashboardBoxShadow} ${palette.light.boxShadows.prussianBlue}3d`,
    '&:active': {
      backgroundColor: backgroundPrussianBlue,
    },
    '&:hover': {
      backgroundColor: backgroundPrussianBlue,
    },
  });
});

export const ArrowBackIconStyled = styled( ArrowBackIcon )(() => ({
  fill: palette.light.icons.white,
}));
