import { gql, MutationTuple, useMutation } from '@apollo/client';

import { publicGraphQLURL } from '../../../../config';

export const NEW_SIGNUP = gql`
mutation Mutation($email: NonEmptyString!, $password: NonEmptyString!, $privacyPolicyAccepted: Boolean!, $validationCode: NonEmptyString) {
  signUp(email: $email, password: $password, privacyPolicyAccepted: $privacyPolicyAccepted, validationCode: $validationCode)
}
`;

interface ISignupParams {
  email: string;
  password: string;
  privacyPolicyAccepted: boolean;
  validationCode?: string;
}

interface ISignUpResponse {
  signup: boolean
}

export const useMutationSignup = ():
MutationTuple<ISignUpResponse, ISignupParams> =>
  useMutation<ISignUpResponse, ISignupParams>(
    NEW_SIGNUP,
    {
      context: {
        uri: publicGraphQLURL,
      },
    },
  );
