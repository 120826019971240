import { Theme } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../hooks/useGetPaletteFromWhiteLabel';
import userAuth from '../../hooks/userAuth';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';
import palette from '../learnerPalette';

// ----------------------------------------------------------------------

export default function Input( theme: Theme ): unknown {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPaletteWL = wlPalette?.background?.redViolet ?? theme.palette.primary.main;
  const colorPalette = isLearner ? colorPaletteWL : palette.light.text.prussianBlue;

  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
          color: colorPalette,
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colorPalette} !important`,
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colorPalette} !important`,
          },
        },
      },
    },
  };
}
