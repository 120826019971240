import icons from '../constants/icons';

export interface ICustomFavicon {
  url: string;
}

export const getCustomFavicon = ({ url }: ICustomFavicon ): string => {
  if ( !url ) {
    return icons.FAVICON as string;
  }

  return url === icons.DEFAULT_ICON ? icons.FAVICON : url;
};
