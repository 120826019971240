import {
  useQuery, QueryResult, gql, QueryTuple, useLazyQuery,
  ApolloError,
} from '@apollo/client';

import { client } from '../../../../apollo/ApolloConfig';
import { getPolices } from '../../../../utils/gqlPolicesUtil';

export const REFETCH_STUDENT_DATA = gql`
query GetLearnerEntireProfile($studentId: NonEmptyString!) {
    getLearnerEntireProfile(studentId: $studentId) {
      jobPreferences {
        jobTitleId
        jobTitleName
        jobTitleSkillSet {
          skill
          skillName
          skillType
          jobTitle
          studentId
          skillTypeTranslated
          validatedSkill
        }
      }
      recommendedOccupations {
        id
        job
        match
      }
      recommendationsByMajor {
        percentageOfMajorMatch {
          id
          match
          name
        }
        studentMajor {
          majorId
          tblMajor {
            id
            name
          }
        }
      }
      recommendedSkills {
        id
        name
        percentage
        type
      }
    }
  }
`;

export const GET_LEARNER_ENTERI_PROFILE = gql`
  query GetLearnerEntireProfile($studentId: NonEmptyString!, $defaultProvidersId: String) {
    getLearnerEntireProfile(studentId: $studentId, defaultProvidersId: $defaultProvidersId) {
      id
      email
      name
      lastName
      cvName
      jobPreferences {
        jobTitleId
        jobTitleName
        match
        studentId
        jobTitleSkillSet {
          id
          skill
          skillName
          skillType
          jobTitle
          score
          studentId
          skillTypeTranslated
          validatedSkill
        }
        skillMatch {
          averagePercentage
          human
          specialized
          specializedAcquired
          totalSpecialized
          humanAcquired
          totalHuman
        }
      }
      recommendedSkills {
        id
        name
        percentage
        type
      }
      recommendedOccupations {
        id
        job
        match
      }
      recommendationsByMajor {
        percentageOfMajorMatch {
          id
          match
          name
        }
        studentMajor {
          id
          majorId
          studentId
          tblMajor {
            id
            name
          }
        }
      }
      studentSkillForCoursesRecommendation {
          skillId
          skillName
          skillType
          skillTypeTranslated
          course(defaultProvidersId: $defaultProvidersId) {
            id
            name
            provider
            url
            imageUrl
            logoUrl
            hasMagicLink
            magicLink
          }
        }
    }
  }
`;

export const GET_STUDENT_RECOMMENDED_COURSES = gql`
  query GetLearnerEntireProfile($studentId: NonEmptyString!) {
    getLearnerEntireProfile(studentId: $studentId) {
      studentSkillForCoursesRecommendation {
        course {
          id
          imageUrl
          logoUrl
          name
          provider
          url
          hasMagicLink
          magicLink
        }
        skillId
        skillName
        skillType
        skillTypeTranslated
      }
    }
  }
`;

export const GET_SKILLS_RECOMMENDED = gql`
  query GetLearnerEntireProfile($studentId: NonEmptyString!) {
    getLearnerEntireProfile(studentId: $studentId) {
      recommendedSkills {
        id
        name
        percentage
        type
        translatedType        
      }
    }
  }
`;

export const GET_LEARNER_ENTIRE_HOME_PROFILE = gql`
  query GetLearnerEntireProfile($studentId: NonEmptyString!) {
    getLearnerEntireProfile(studentId: $studentId) {
      cvName
      email
      id
      jobPreferences {
        jobTitleId
        jobTitleName
        match
      }
      lastName
      name
      recommendationsByMajor {
        percentageOfMajorMatch {
          id
          match
          name
        }
        studentMajor {
          id
          majorId
          studentId
          tblMajor {
            id
            name
          }
        }
      }
      recommendedOccupations {
        id
        job
        match
      }
      recommendedSkills {
        id
        name
        percentage
        type
      }
      studentSkillForCoursesRecommendation {
        course {
          imageUrl
          logoUrl
          id
          name
          provider
          url
          hasMagicLink
          magicLink
        }
        skillId
        skillName
        skillType
      }
    }
  }
`;

export const RECOMMENDED_SKILLS = gql`
query GetInstitutions($studentId: NonEmptyString!) {
  getLearnerEntireProfile(studentId: $studentId) {
    recommendedSkills {
      id
      name
      percentage
      type
      translatedType
    }
  }
}
`;

export interface IGetLearnerEntireProfileParams {
  studentId?: string,
  defaultProvidersId?: string
}

export interface IJobTitleSkillSet {
  skill: string;
  skillName: string;
  skillType: string;
  skillTypeTranslated?: string;
  jobTitle: string;
  studentId: string;
  validatedSkill: boolean;
}
export interface ISkillMatch {
  averagePercentage: number;
  human: number;
  specialized: number;
  specializedAcquired: number;
  totalSpecialized: number;
  humanAcquired: number;
  totalHuman: number;
}
export interface IJobTitles {
  id: string;
  score: number;
  match: {
    name: string;
    match: number;
    id: string;
  };
}
export interface IRelatedOccupations {
  jobTitles: IJobTitles[];
}
export interface IJobPreferences {
  jobTitleId: string;
  jobTitleName: string;
  match: number;
  studentId: string;
  jobTitleSkillSet: IJobTitleSkillSet[];
  skillMatch: ISkillMatch;
  relatedOccupations: IRelatedOccupations;
}
export interface IJobPreferencesQueryHook {
  jobTitleId: string;
  jobTitleName: string;
  match: number;
  studentId: string;
  jobTitleSkillSet: IJobTitleSkillSet[];
  skillMatch?: ISkillMatch;
  relatedOccupations?: IRelatedOccupations;
}

export interface IJobPreferencesRefetch {
  jobTitleId: string;
  jobTitleName: string;
  studentId: string;
  jobTitleSkillSet: IJobTitleSkillSet[];
}
export interface IRecommendedSkills {
  id: string;
  name: string;
  percentage: number;
  type: string;
}
export interface IRecommendedOccupations {
  id: string;
  job: string;
  match: number;
}
export interface IPercentageOfMajorMatch {
  id: string;
  match: number;
  name: string;
}
export interface IStudentMajor {
  id: string;
  majorId: string;
  studentId: string;
  tblMajor?: {
    id: string;
    name: string;
  }
}
export interface IStudentSkillForCoursesRecommendationCourse {
  id: string;
  name: string;
  provider: string;
  url: string;
  imageUrl: string;
  logoUrl: string;
  hasMagicLink?: boolean;
  magicLink?: string;
}
export interface IStudentSkillForCoursesRecommendation {
  skillId: string;
  skillName: string;
  skillType: string;
  course: IStudentSkillForCoursesRecommendationCourse;
}
export interface IGetLearnerEntireProfile {
  id: string;
  email: string;
  name: string;
  lastName: string;
  cvName: string;
  jobPreferences: IJobPreferences[];
  recommendedSkills: IRecommendedSkills[];
  recommendedOccupations: IRecommendedOccupations[];
  recommendationsByMajor: {
    percentageOfMajorMatch: IPercentageOfMajorMatch[];
    studentMajor: IStudentMajor;
  }[];
  studentSkillForCoursesRecommendation: IStudentSkillForCoursesRecommendation[];
}

export interface IGetLearnerEntireHomeProfile {
  id: string;
  email: string;
  name: string;
  lastName: string;
  cvName: string;
  jobPreferences: IJobPreferences[];
  recommendedSkills: IRecommendedSkills[];
  recommendedOccupations: IRecommendedOccupations[];
  recommendationsByMajor: {
    percentageOfMajorMatch: IPercentageOfMajorMatch[];
    studentMajor: IStudentMajor;
  }[];
  studentSkillForCoursesRecommendation: IStudentSkillForCoursesRecommendation[];
}

export interface IGetLearnerEntireProfileResponse {
  getLearnerEntireProfile: IGetLearnerEntireProfile | undefined;
}

export interface IGetLearnerEntireProfileCoursesResponse {
  getLearnerEntireProfile: undefined | {
    studentSkillForCoursesRecommendation: IStudentSkillForCoursesRecommendation[];
  };
}

export interface IGetRecommendedSkillsResponse {
  getLearnerEntireProfile: undefined | {
    recommendedSkills: IRecommendedSkills[];
  };
}

interface IGetLearnerEntireHomeProfileParams {
  studentId?: string,
}

export const useLazyGetLearnerEntireProfile = ( fromCache?: boolean ):
QueryTuple<IGetLearnerEntireProfileResponse, IGetLearnerEntireProfileParams> =>
  useLazyQuery<IGetLearnerEntireProfileResponse, IGetLearnerEntireProfileParams>(
    GET_LEARNER_ENTERI_PROFILE, { ...getPolices( fromCache ) },
  );

export const useQueryGetLearnerEntireProfile = (
  studentId?: string, fromCache?: boolean,
):
QueryResult<IGetLearnerEntireProfileResponse, IGetLearnerEntireProfileParams> =>
  useQuery<IGetLearnerEntireProfileResponse, IGetLearnerEntireProfileParams>(
    GET_LEARNER_ENTERI_PROFILE, {
      ...getPolices( fromCache ),
      variables: {
        studentId,
      },
    },
  );

export const useQueryGetCoursesRecommendation = (
  studentId?: string, fromCache?: boolean,
):
QueryResult<IGetLearnerEntireProfileCoursesResponse, IGetLearnerEntireProfileParams> =>
  useQuery<IGetLearnerEntireProfileCoursesResponse, IGetLearnerEntireProfileParams>(
    GET_STUDENT_RECOMMENDED_COURSES, {
      ...getPolices( fromCache ),
      variables: {
        studentId,
      },
    },
  );

export const useQueryGetSkillsRecommended = (
  studentId?: string, fromCache?: boolean,
):
QueryResult<IGetRecommendedSkillsResponse, IGetLearnerEntireProfileParams> =>
  useQuery<IGetRecommendedSkillsResponse, IGetLearnerEntireProfileParams>(
    GET_SKILLS_RECOMMENDED, {
      ...getPolices( fromCache ),
      variables: {
        studentId,
      },
    },
  );

export interface IRecommendedByMajorRefetch {
  percentageOfMajorMatch: IPercentageOfMajorMatch[];
  studentMajor: IStudentMajor;
}

interface IGetLearnerProfileOnChangeResponse {
  getLearnerEntireProfile: {
    jobPreferences: IJobPreferencesRefetch[];
    recommendedOccupations: IRecommendedOccupations[];
    recommendationsByMajor: IRecommendedByMajorRefetch[];
    recommendedSkills: IRecommendedSkills[];
  }
}
interface IGetLearnerProfileOnChangeRefetch {
  data: IGetLearnerProfileOnChangeResponse,
  error: ApolloError | undefined,
}

interface IGetCoursesRecommendationOnRefetch {
  data: IGetLearnerEntireProfileCoursesResponse
  error: ApolloError | undefined,
}

export interface IGetLearnerEntireHomeProfileResponse {
  getLearnerEntireHomeProfile: IGetLearnerEntireHomeProfile | undefined;
}

interface IGetRecommendedSkillsParams {
  studentId: string;
}

export const getLearnerProfileOnChangeRefetch = async (
  studentId: string,
): Promise<IGetLearnerProfileOnChangeRefetch> => {
  const data = await client.query({
    query: REFETCH_STUDENT_DATA,
    variables: {
      studentId,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data: data.data,
    error: data?.error,
  };
};

export const getCoursesRecommendationOnRefetch = async (
  studentId: string,
): Promise<IGetCoursesRecommendationOnRefetch> => {
  const data = await client.query({
    query: GET_STUDENT_RECOMMENDED_COURSES,
    variables: {
      studentId,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data: data.data,
    error: data?.error,
  };
};

export const useLazyGetLearnerEntireHomeProfile = ( fromCache?: boolean ):
QueryTuple<IGetLearnerEntireHomeProfileResponse, IGetLearnerEntireHomeProfileParams> =>
  useLazyQuery<IGetLearnerEntireHomeProfileResponse, IGetLearnerEntireHomeProfileParams>(
    GET_LEARNER_ENTIRE_HOME_PROFILE, { ...getPolices( fromCache ) },
  );

export const useQueryGetLearnerEntireHomeProfile = (
  studentId?: string, fromCache?: boolean,
):
QueryResult<IGetLearnerEntireHomeProfileResponse, IGetLearnerEntireHomeProfileParams> =>
  useQuery<IGetLearnerEntireHomeProfileResponse, IGetLearnerEntireHomeProfileParams>(
    GET_LEARNER_ENTIRE_HOME_PROFILE, {
      ...getPolices( fromCache ),
      variables: {
        studentId,
      },
    },
  );

export const useLazyGetStudentRecommendedSkills = ( fromCache?: boolean ):
QueryTuple<IGetRecommendedSkillsResponse, IGetRecommendedSkillsParams> =>
  useLazyQuery<IGetRecommendedSkillsResponse, IGetRecommendedSkillsParams>(
    RECOMMENDED_SKILLS,
    { ...getPolices( fromCache ) },
  );

export const useQueryGetStudentRecommendedSkills = ( studentId: string, fromCache?: boolean ):
QueryResult<IGetRecommendedSkillsResponse, IGetRecommendedSkillsParams> =>
  useQuery<IGetRecommendedSkillsResponse, IGetRecommendedSkillsParams>(
    RECOMMENDED_SKILLS,
    {
      variables: { studentId },
      ...getPolices( fromCache ),
    },
  );
