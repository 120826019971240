import {
  gql, QueryResult, useQuery,
} from '@apollo/client';

import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_INSTITUTION_PROVIDER = gql`
query GetInstitutionsCourseProviders ($InstitutionId: NonEmptyString!) {
  getInstitutionCourseProviders (institutionId: $InstitutionId) {
        id
        institutionId
        providerId
  }
}
`;

export interface IInsitutionCourseProviders {
  id: number,
  institutionId: string,
  providerId: string,
}

export interface IInsitutionCourseProviderParams {
  InstitutionId: string
}

export interface IGetInsitutionCourseProvidersResponse {
  getInstitutionCourseProviders: IInsitutionCourseProviders[]
}

export const useQueryGetInstitutionCourseProviders = (
  institutionId: string,
  fromCache?: boolean,
): QueryResult<IGetInsitutionCourseProvidersResponse, IInsitutionCourseProviderParams> => {
  const shouldSkip = !institutionId;

  return useQuery<IGetInsitutionCourseProvidersResponse, IInsitutionCourseProviderParams>(
    GET_INSTITUTION_PROVIDER,
    {
      variables: { InstitutionId: institutionId },
      skip: shouldSkip,
      ...getPolices( fromCache ),
    },
  );
};
