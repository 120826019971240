import { alpha, Theme } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../hooks/useGetPaletteFromWhiteLabel';
import userAuth from '../../hooks/userAuth';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';

// ----------------------------------------------------------------------

export default function Lists( theme: Theme ): unknown {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPaletteWL = wlPalette?.background?.yaleBlue ?? theme.palette.primary.main;
  const backgroundColorPaletteWL = wlPalette?.background?.yaleBlue ?? theme.palette.action.active;
  const colorPaletteAdvisor = theme.palette.primary.main;
  const backgroundColorPaletteAdvisor = theme.palette.action.active;
  const colorPalette = isLearner ? colorPaletteWL : colorPaletteAdvisor;
  const backgroundColorPalette = isLearner ? backgroundColorPaletteWL : backgroundColorPaletteAdvisor;

  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&.active': {
            color: `${colorPalette} !important`,
            backgroundColor: alpha( backgroundColorPalette, 0.1 ),
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing( 2 ),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing( 2 ),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  };
}
