import { BoxProps } from '@mui/material';
import {
  forwardRef, ReactNode, useContext, useEffect, useState,
} from 'react';
import { Helmet } from 'react-helmet-async';

import { BoxStyled } from './PageStyles';
import { useLoadersCache } from '../../cache/common/loadersReactiveVar';
import icons from '../../constants/icons';
import { FeatureFlagsContext } from '../../contexts/FeatureFlagsContext';
import userAuth from '../../hooks/userAuth';
import { getCustomFavicon } from '../../utils/getCustomFavicon';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';

interface IPageProps extends BoxProps {
  title?: string;
  children: ReactNode;
}

const Page = forwardRef<HTMLDivElement, IPageProps>(({ title = '', children, ...other }, ref ): JSX.Element => {
  const { auth } = userAuth();
  const { loaders } = useLoadersCache();
  const { features } = useContext( FeatureFlagsContext );
  const [showFavIcon, setShowFavIcon] = useState<boolean>( false );

  const getFavIcon = (): string => {
    if ( features.customFavicon ) {
      return getCustomFavicon( features.customFavicon );
    }

    switch ( auth?.userRole ) {
      case ROLE_ENUM.ADMIN:
        return icons.FAVICON_ADVISOR;

      case ROLE_ENUM.STUDENT:
        return icons.FAVICON_LEARNER;

      default:
        return icons.FAVICON;
    }
  };

  useEffect(() => {
    if ( loaders.length ) {
      setShowFavIcon( false );
    } else {
      setShowFavIcon( true );
    }
  }, [loaders]);

  return (
    <BoxStyled ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
        { showFavIcon && <link rel="icon" type="image/x-icon" href={getFavIcon()} sizes="16x16" /> }
        { !showFavIcon && <link rel="icon" type="image/x-icon" href={getFavIcon()} sizes="16x16" /> }
      </Helmet>
      {children}
    </BoxStyled>
  );
});

export default Page;
