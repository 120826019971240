import environmentVariables from './appVariables';
import parseProviderString from './utils/parseProviderString';

export const providerFilterList = parseProviderString( environmentVariables?.reactAppProviders )?.map(( provider ) => ({
  id: provider,
  name: provider,
})) ?? [];

export const iframeConfig = ( lang: string ): string => (
  lang === 'en'
    ? environmentVariables?.contactFormUrlIframeEn ?? ''
    : environmentVariables?.contactFormUrlIframeEs ?? ''
);

export const cookieExpires = 1;
export const cookieSameSite = window.self !== window.top ? 'none' : 'strict';

export type ICookies=
  | 'token'
  | 'skillFilterId'
  | 'selectedSkill'
  | 'uidUser'
  | 'learnerProfile'
  | 'remember'
  | 'majorId'
  | 'skillForCoursesRecommendations'
  | 'recommendedOccupationsByMajor'
  | 'jobPreferencesSkillSet'
  | 'jobPreferencesSkillMatch'
  | 'jobPreferencesRelatedOccupations'
  | 'recommendedSkills'
  | 'recommendedOccupations'
  | 'kanbanData'
  | 'preferredLanguage';

export const ENVIRONMENT: string = environmentVariables?.environment ?? 'development';

export const webSocketURL = ENVIRONMENT === 'development'
  ? `${environmentVariables?.reactAppGraphqlSocketDev}/graphql`
  : `${environmentVariables?.reactAppGraphqlSocket}/graphql`;

export const publicGraphQLURL = ENVIRONMENT === 'development'
  ? `${environmentVariables?.reactAppApiGraphqlDev}/public/graphql`
  : `${environmentVariables?.reactAppApiGraphql}/public/graphql`;

export const reactAppProviders = environmentVariables?.reactAppProviders ?? '';
