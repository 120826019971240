import './TranslationComponentConfig';
import {
  useState, useRef, useEffect, useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  CheckRoundedIconStyled,
  ContainerButtonStyled,
  ExpandLessRoundedIconStyled,
  ExpandMoreRoundedIconStyled,
  ListItemButtonStyled,
  ListStyled,
  ListSubheaderStyled,
  MenuPopoverStyled,
  SelectOptionStyled,
  WorldIconStyled,
} from './styles/TranslationComponentStyles';
import { kanbanJobTitleId, kanbanSkillType, userMajors } from '../../../cache/cache';
import { renderNotification } from '../../../cache/learner/homeReactiveVars';
import { invalidMajor } from '../../../cache/learner/invalidMajorReactiveVar';
import { kanbanDataVar } from '../../../cache/learner/kanbanReactiveVar';
import icons from '../../../constants/icons';
import { INVALID_MAJOR_ID } from '../../../constants/invalidMajorId';
import { FeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import useKanbanDataHook from '../../../hooks/kanbanDataHook/useKanbanDataHook';
import useNotificationsHook from '../../../hooks/notificationsHook/useNotificationsHook';
import userAuth from '../../../hooks/userAuth';
import useStudentRefetch from '../../../hooks/useStudentRefetch';
import { useMutationChangePreferredLanguage } from '../../../operations/mutations/loginUser/changePreferredLanguage';
import { useLazyGetStudentMajors } from '../../../operations/query/students/useGetStudentMajors';
import { useLazyGetStudentNotification } from '../../../operations/query/students/useGetStudentNotification';
import { setCookie, getCookie } from '../../../utils/cookiesUtil';
import { tokenDecode } from '../../../utils/jwtUtils';
import CircularLoaderComponent from '../CircularLoaderComponent/CircularLoaderComponent';

export interface IHandleListItems {
  id: number;
  language: string;
  languageId: string;
}

const TranslationComponent: React.FC = () => {
  const { t } = useTranslation( 'TranslationComponent' );
  const { features } = useContext( FeatureFlagsContext );
  const { auth, setToken } = userAuth();
  const anchorRef = useRef<HTMLButtonElement>( null );
  const [open, setOpen] = useState<boolean>( false );
  const [isLoadingPreferredLanguage, setIsLoadingPreferredLanguage] = useState<boolean>( false );
  const [selectedItem, setSelectedItem] = useState<string>( t( 'CURRENT_LANGUAGE' ));
  const [selectedLanguageId, setSelectedLanguageId] = useState<string>(
    auth?.preferredLanguage ?? features.defaultLanguage?.language,
  );
  const [activeBackground, setActiveBackground] = useState<number | null>( null );
  const [changePreferedLanguageHandler] = useMutationChangePreferredLanguage();
  const languageList = t( 'POPOVER_CONTENT', { returnObjects: true });
  const { operations: notificationOps } = useNotificationsHook();
  const { operations } = useKanbanDataHook( kanbanDataVar, kanbanSkillType, kanbanJobTitleId );
  const [getData, { data: majorResponse }] = useLazyGetStudentMajors( true );
  const studentId = auth?.student?.id ?? '';
  const { refetchStudentData, refetchPathways } = useStudentRefetch();
  const { i18n } = useTranslation();

  const [
    getDataNotifications,
    {
      data: dataNotifications,
    },
  ] = useLazyGetStudentNotification( false );

  const handleOpen = (): void => {
    setOpen( true );
  };

  const handleClose = (): void => {
    setOpen( false );
  };

  const handleLanguageChangeMutation = async ( preferredLanguage: string ): Promise<void> => {
    setIsLoadingPreferredLanguage( true );

    if ( !auth ) {
      const preferredLanguageFromCookie = getCookie( 'preferredLanguage' ) ?? features.defaultLanguage?.language;

      i18n.changeLanguage( preferredLanguageFromCookie );
      setSelectedLanguageId( preferredLanguageFromCookie ?? '' );
      setIsLoadingPreferredLanguage( false );

      return;
    }

    try {
      const { data } = await changePreferedLanguageHandler({
        variables: {
          preferredLanguage,
        },
      });

      i18n.changeLanguage( preferredLanguage );

      refetchStudentData?.();
      refetchPathways?.();

      getData({
        variables: {
          studentId,
        },
      });

      const token = data?.changePreferredLanguage?.jwtToken ?? '';
      const tokenDecoded = tokenDecode( token );

      setToken( tokenDecoded, tokenDecoded.expiresIn, token );
      operations.refetchKanbanData();
      getDataNotifications({
        variables: {
          studentId,
        },
        fetchPolicy: 'network-only',
      });
      setIsLoadingPreferredLanguage( false );
    } catch ( error ) {
      setIsLoadingPreferredLanguage( false );
      notificationOps.addNotification( 'danger', t( 'SOMETHING_WENT_WRONG' ), t( 'ERROR' ));
    }
  };

  const selectedLanguage = useMemo(() => {
    if ( !Array.isArray( languageList )) {
      return t( 'CURRENT_LANGUAGE' );
    }

    const languageToShow = ( languageList as IHandleListItems[]).find(( item ) =>
      item.languageId === selectedLanguageId );

    return languageToShow?.language ?? t( 'CURRENT_LANGUAGE' );
  }, [languageList, selectedLanguageId, t]);

  useEffect(() => {
    if ( dataNotifications?.getStudentNotification ) {
      renderNotification( dataNotifications?.getStudentNotification );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNotifications]);

  useEffect(() => {
    if ( selectedLanguageId !== auth?.preferredLanguage ) {
      handleLanguageChangeMutation( selectedLanguageId );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguageId]);

  const handleListItems = ( listItems: IHandleListItems[]): JSX.Element[] => (
    Array.isArray( listItems ) ? (
      listItems.map(( item ) => (
        <ListItemButtonStyled
          data-testid={item.language}
          key={item.id}
          onClick={() => {
            handleClose();
            setSelectedItem( item.language );
            setActiveBackground( item.id );
            setSelectedLanguageId( item.languageId );
            setCookie( 'preferredLanguage', item.languageId );
          }}
          activebackground={selectedItem === item.language || activeBackground === item.id ? 1 : 0}
          activeborderradius={item.id === 2 ? 1 : 0}
        >
          {item.language}
          {( selectedItem === item.language || activeBackground === item.id ) && (
            <CheckRoundedIconStyled />
          )}
        </ListItemButtonStyled>
      ))) : ([])
  );

  useEffect(() => {
    if ( majorResponse?.getStudentMajors ) {
      invalidMajor(
        majorResponse?.getStudentMajors[0]?.tblMajor?.id === INVALID_MAJOR_ID
          || !majorResponse?.getStudentMajors[0]?.tblMajor,
      );
      const majors = majorResponse?.getStudentMajors.map(( item ) => ({
        name: item.tblMajor?.name ?? '',
      }));

      userMajors( majors );
    }
  }, [majorResponse]);

  return (
    <>
      <ContainerButtonStyled
        data-testid="test_pop_button"
        ref={anchorRef}
        onClick={handleOpen}
      >
        {isLoadingPreferredLanguage ? (
          <CircularLoaderComponent
            isLoading
            color="small-dark"
            type="withoutText"
            background="transparent"
          />
        ) : (
          <WorldIconStyled
            data-testid="test_world"
            src={icons.WORLD}
          />
        )}
        <SelectOptionStyled>
          {selectedLanguage}
        </SelectOptionStyled>
        {open ? (
          <ExpandLessRoundedIconStyled />
        ) : (
          <ExpandMoreRoundedIconStyled />
        )}
      </ContainerButtonStyled>

      <MenuPopoverStyled
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        arrowPopover={false}
        direction="right"
      >
        <ListStyled
          subheader={(
            <ListSubheaderStyled>
              {t( 'TITLE_POPOVER' )}
            </ListSubheaderStyled>
          )}
        >
          {handleListItems( t( 'POPOVER_CONTENT', { returnObjects: true }) as IHandleListItems[]) }
        </ListStyled>
      </MenuPopoverStyled>
    </>
  );
};

export default TranslationComponent;
