export enum FeaturesEnum {
  hideLearnerHelp = 'HIDE_LEARNER_HELP',
  learnerHideProfileEditButton = 'LEARNER_HIDE_PROFILE_EDIT_BUTTON',
  sso = 'SSO',
  registerUser = 'REGISTER_USER',
  embededInIframe = 'EMBEDED_IN_IFRAME',
  specializedMajors = 'ENABLE_SPECIALIZED_MAJORS',
  occupationsLimitedEnabled = 'OCCUPATIONS_LIMITED_ENABLED',
  samlGriky = 'SAML_GRIKY',
  samlUVA = 'SAML_UVA',
  iframeAndPlatform = 'IFRAME_AND_PLATFORM',
  hideCareerTrendsScreen = 'HIDE_CAREER_TRENDS_SCREEN',
  hideLearningPathWaysScreen = 'HIDE_LEARNING_PATHWAYS_SCREEN',
  qsInstance = 'QS_INSTANCE',
  hideAdvisorStudentList = 'HIDE_ADVISOR_STUDENT_LIST',
  showHobbies = 'SHOW_HOBBIES',
  institutionWizardQuestion = 'INSTITUTION_WIZARD_QUESTION',
  typeOfUserWizardQuestion = 'TYPE_OF_USER_WIZARD_QUESTION',
  learningPathShowInOccupations = 'LEARNING_PATH_SHOW_IN_OCCUPATIONS',
  hideAdvisorSkillInsights = 'HIDE_ADVISOR_SKILL_INSIGHTS',
  hideAdvisorStudentOverview = 'HIDE_ADVISOR_STUDENT_OVERVIEW',
  hideAdvisorStudentAdvising = 'HIDE_ADVISOR_STUDENT_ADVISING',
  hideAdvisorContactUs = 'HIDE_ADVISOR_CONTACT_US',
  hideAdvisorFAQ = 'HIDE_ADVISOR_FAQ',
  showStudentTypeFilter = 'UVA_ADVISOR_SHOW_FILTER_BY_STUDENT_TYPE',
  defaultLevelOfStudy = 'DEFAULT_LEVEL_OF_STUDY',
  showLPProvidersCoursesNumber='LEARNER_LP_SHOW_COURSES_NUMBER_PROVIDERS',
  academicProgramEnabled= 'ACADEMIC_PROGRAM_ENABLED',
  showCourseRecommendationsMessage = 'SHOW_COURSE_RECOMMENDATIONS_MESSAGE',
  showFooter = 'SHOW_FOOTER',
  showCareerDiscovery ='SHOW_CAREER_DISCOVERY',
  whiteLabel = 'WHITE_LABEL',
  showOffice365 = 'SHOW_OFFICE365',
  showInfoSkillsSection = 'SHOW_INFO_SKILLS_SECTION',
  showInfoLearningPathSection = 'SHOW_INFO_LP_SECTION',
  showInfoWizard = 'SHOW_INFO_WIZARD',
  showEmployabilityStatus = 'SHOW_EMPLOYABILITY_STATUS',
  showBadgeSkillValidated = 'SHOW_BADGE_SKILL_VALIDATED',
  customStudentTypeEnabled = 'CUSTOM_STUDENT_TYPE_ENABLED',
  singleTenantSSO = 'QSEM_SSO_SINGLETENANT',
}
