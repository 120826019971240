import { Theme } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../hooks/useGetPaletteFromWhiteLabel';
import userAuth from '../../hooks/userAuth';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';
import palette from '../learnerPalette';

// ----------------------------------------------------------------------

export default function Tabs( theme: Theme ): unknown {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;

  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorLearner = wlPalette?.background?.redViolet ?? theme.palette.primary.main;
  const colorAdvisor = palette.light.text.prussianBlue;
  const colorPalette = isLearner ? colorLearner : colorAdvisor;

  return {
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          fontWeight: theme.typography.fontWeightMedium,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          '&.Mui-selected': {
            color: colorPalette,
          },
          '&:not(:last-of-type)': {
            marginRight: theme.spacing( 5 ),
          },
          '@media (min-width: 600px)': {
            minWidth: 48,
          },
        },
        labelIcon: {
          minHeight: 48,
          flexDirection: 'row',
          '& > *:first-of-type': {
            marginBottom: 0,
            marginRight: theme.spacing( 1 ),
          },
        },
        wrapper: {
          flexDirection: 'row',
          whiteSpace: 'nowrap',
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.primary,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: '50%',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colorPalette,
        },
      },
    },
  };
}
