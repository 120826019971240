import {
  gql,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useQuery,
} from '@apollo/client';

import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_JOB_RECOMMENDATIONS_BY_STUDENT_ID_ATTRIBUTES = `
id
job
match
`;

export const GET_JOB_RECOMMENDATIONS_BY_STUDENT_ID = gql`
  query GetJobRecommendationsByStudentId($studentId: NonEmptyString!, $limit: Int) {
    jobsWithMatch: getJobRecommendationsByStudentId(studentId: $studentId, limit: $limit) {
      id
      job
      match
    }
  }
`;

interface IJobWithMatchParams {
  studentId: string;
  limit?: number;
}

export interface IJobWithMatch {
  id: string;
  job: string;
  match: number;
}

export interface IGetJobsWithMatchResponse {
  jobsWithMatch: IJobWithMatch[];
}

export const useLazyGetJobRecommendationsByStudentId = ( fromCache?: boolean ):
QueryTuple<IGetJobsWithMatchResponse, IJobWithMatchParams> =>
  useLazyQuery<IGetJobsWithMatchResponse, IJobWithMatchParams>(
    GET_JOB_RECOMMENDATIONS_BY_STUDENT_ID,
    { ...getPolices( fromCache ) },
  );

export const useQueryGetJobRecommendationsByStudentId = ( studentId: string, limit?: number, fromCache?: boolean ):
QueryResult<IGetJobsWithMatchResponse, IJobWithMatchParams> =>
  useQuery<IGetJobsWithMatchResponse, IJobWithMatchParams>(
    GET_JOB_RECOMMENDATIONS_BY_STUDENT_ID, {
      variables: { studentId, limit },
      ...getPolices( fromCache ),
    },
  );
