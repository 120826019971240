import { gql, MutationTuple, useMutation } from '@apollo/client';

export const CREATE_STUDENT_LEARNING_PATHWAY = gql`
mutation CreateStudentLearningPathway($studentId: NonEmptyString!, $pathwayId: NonEmptyString!) {
  createStudentLearningPathway(studentId: $studentId, pathwayId: $pathwayId)
}
`;

interface ICreateStudentLearningPathwayParams {
  studentId: string;
  pathwayId: string;
}

interface ICreateStudentLearningPathwayResponse {
  createStudentLearningPathway: boolean;
}

export const useMutationCreateStudentLearningPathway = ():
MutationTuple<ICreateStudentLearningPathwayResponse, ICreateStudentLearningPathwayParams> =>
  useMutation<ICreateStudentLearningPathwayResponse, ICreateStudentLearningPathwayParams>(
    CREATE_STUDENT_LEARNING_PATHWAY,
  );
