import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useTheme, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../../../hooks/useGetPaletteFromWhiteLabel';
import userAuth from '../../../../hooks/userAuth';
import palette from '../../../../theme/learnerPalette';
import { ROLE_ENUM } from '../../../../views/Auth/Login/interfaces/IGoogleUser';
import { styleConfigs } from '../../../resources/styleConfigs';
import { MIconButton } from '../../../template-minimal/@material-extend';
import MenuPopover from '../../../template-minimal/MenuPopoverComponent/MenuPopover';
import ListItemButtonWrapper from '../../../wrappers/ListItemButtonWrapper';
import ListSubheaderWrapper from '../../../wrappers/ListSubheaderWrapper';
import ListWrapper from '../../../wrappers/ListWrapper';
import TypographyWrapper from '../../../wrappers/TypographyWrapper';
import IconComponent from '../../IconComponent/IconComponent';

export const ContainerButtonStyled = styled( MIconButton )(() => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const backgroundColorPalette = wlPalette?.text?.prussianBlue ?? palette.light.text.prussianBlue;

  return ({
    color: backgroundColorPalette,
    padding: styleConfigs.marginPadding.empty,
    borderRadius: styleConfigs.borders.radius16,
    '&:hover': {
      backgroundColor: alpha( backgroundColorPalette, 0.1 ),
    },
  });
});

export const WorldIconStyled = styled( IconComponent )(() => ({
  marginBlock: styleConfigs.marginPadding.custom8,
  marginInlineStart: styleConfigs.marginPadding.custom3,
}));

export const SelectOptionStyled = styled( TypographyWrapper )(() => ({
  fontWeight: styleConfigs.fonts.bold,
  fontSize: styleConfigs.fonts.fontSize14,
  paddingBlock: styleConfigs.marginPadding.custom11,
  paddingInline: `${styleConfigs.marginPadding.custom8px} ${styleConfigs.marginPadding.custom10px}`,
}));

export const MenuPopoverStyled = styled( MenuPopover )(() => ({
  '& .MuiPaper-root': {
    width: styleConfigs.dimensions.width206,
    borderRadius: styleConfigs.borders.radius12,
    [useTheme().breakpoints.down( 'sm' )]: {
      marginBlockStart: styleConfigs.marginPadding.custom15,
      marginInlineStart: styleConfigs.marginPadding.custom10,
    },
  },
}));

export const ListStyled = styled( ListWrapper )(() => ({
  padding: styleConfigs.marginPadding.empty,
}));

export const ListSubheaderStyled = styled( ListSubheaderWrapper )(() => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const backgroundColorPalette = wlPalette?.text?.prussianBlue ?? palette.light.text.prussianBlue;

  return ({
    fontWeight: styleConfigs.fonts.bold,
    fontSize: styleConfigs.fonts.fontSize16,
    lineHeight: styleConfigs.fonts.lineHeight22,
    color: backgroundColorPalette,
    paddingBlock: styleConfigs.marginPadding.custom14,
    paddingInline: styleConfigs.marginPadding.custom17,
    borderBottom: `${styleConfigs.borders.borderLine} ${palette.light.borders.cadetGrey}`,
  });
});

export const ListItemButtonStyled = styled( ListItemButtonWrapper )((
  { activebackground, activeborderradius }: {activebackground: number, activeborderradius: number},
) => {
  const wlPalette = useGetPaletteFromWhiteLabel();
  const backgroundColorPalette = wlPalette?.text?.mistBlue ?? palette.light.background.mistBlue;

  return ({
    justifyContent: 'space-between',
    fontSize: styleConfigs.fonts.fontSize14,
    lineHeight: styleConfigs.fonts.lineHeight22,
    color: palette.light.text.ebonyClay,
    background: activebackground && alpha( backgroundColorPalette, 0.2 ),
    paddingBlock: styleConfigs.marginPadding.custom10,
    paddingInline: `${styleConfigs.marginPadding.custom17px} ${styleConfigs.marginPadding.custom21px}`,
    borderBottom: `${styleConfigs.borders.borderLine} ${palette.light.borders.cadetGrey}`,
    borderEndStartRadius: activeborderradius && styleConfigs.borders.radius12,
    borderEndEndRadius: activeborderradius && styleConfigs.borders.radius12,
    '&:hover': {
      background: alpha( backgroundColorPalette, 0.1 ),
    },
  });
});

export const ExpandLessRoundedIconStyled = styled( ExpandLessRoundedIcon )(() => {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const fillColorPaletteAdvisor = palette.light.text.ebonyClay;

  const wlPalette = useGetPaletteFromWhiteLabel();
  const fillColorPaletteWL = wlPalette?.text?.redViolet ?? palette.light.text.ebonyClay;
  const fillColorPalette = isLearner ? fillColorPaletteWL : fillColorPaletteAdvisor;

  return ({
    fill: fillColorPalette,
  });
});

export const ExpandMoreRoundedIconStyled = styled( ExpandMoreRoundedIcon )(() => {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const fillColorPaletteAdvisor = palette.light.text.ebonyClay;

  const wlPalette = useGetPaletteFromWhiteLabel();
  const fillColorPaletteWL = wlPalette?.text?.redViolet ?? palette.light.text.ebonyClay;
  const fillColorPalette = isLearner ? fillColorPaletteWL : fillColorPaletteAdvisor;

  return ({
    fill: fillColorPalette,
  });
});

export const CheckRoundedIconStyled = styled( CheckRoundedIcon )(() => {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const fillColorPaletteAdvisor = palette.light.text.ebonyClay;

  const wlPalette = useGetPaletteFromWhiteLabel();
  const fillColorPaletteWL = wlPalette?.text?.redViolet ?? palette.light.text.ebonyClay;
  const fillColorPalette = isLearner ? fillColorPaletteWL : fillColorPaletteAdvisor;

  return ({
    fill: fillColorPalette,
  });
});
