import {
  DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import palette from '../../../../theme/learnerPalette';
import { styleConfigs } from '../../../resources/styleConfigs';

export const HeaderContainerStyled = styled( DialogTitle )(({ hasDivider }: {hasDivider: number}) => ({
  width: styleConfigs.dimensions.percent100,
  padding: styleConfigs.marginPadding.empty,
  borderBottom: hasDivider ? `${styleConfigs.borders.borderLine} ${palette.light.background.cadetGrey}` : styleConfigs.borders.none,
}));

export const DialogContentStyled = styled( DialogContent )(() => ({
  padding: styleConfigs.marginPadding.empty,
  color: palette.light.text.prussianBlue,
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: styleConfigs.dimensions.width6,
    background: palette.light.background.transparent,
  },
  '::-webkit-scrollbar-thumb': {
    background: palette.light.background.amethyst,
    borderRadius: styleConfigs.borders.radius3,
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: palette.light.background.mistBlue,
  },
}));

export const FooterContainerStyled = styled( DialogActions )(({ hasDivider }: {hasDivider: number}) => ({
  width: styleConfigs.dimensions.percent100,
  padding: `${styleConfigs.marginPadding.empty} !important`,
  borderTop: hasDivider ? `${styleConfigs.borders.borderLine} ${palette.light.background.cadetGrey}` : styleConfigs.borders.none,
}));
