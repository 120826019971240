import {
  useQuery, QueryResult, gql, QueryTuple, useLazyQuery,
} from '@apollo/client';

import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_STUDENT_NOTIFICATION = gql`
  query StudentNotifications($studentId: NonEmptyString!) {
    getStudentNotification(studentId: $studentId) {
      newNotifications {
        type
        id
        skillName
        occupationName
        date
        skillStatus
      }
      beforeNotifications {
        id
        type
        skillName
        occupationName
        date
        skillStatus
      }
    }
  }
`;

export interface IGetAvailableJobsParams {
  studentId?: string,
}

export interface IGetAvailableJobsResponse {
  getStudentNotification:{
    newNotifications: [],
    beforeNotifications: [],
  }

}

export const useLazyGetStudentNotification = ( fromCache?: boolean ):
QueryTuple<IGetAvailableJobsResponse, IGetAvailableJobsParams> =>
  useLazyQuery<IGetAvailableJobsResponse, IGetAvailableJobsParams>(
    GET_STUDENT_NOTIFICATION,
    { ...getPolices( fromCache ) },
  );

export const useQueryGetStudentNotification = (
  studentId: string,
):
QueryResult<IGetAvailableJobsResponse, IGetAvailableJobsParams> =>
  useQuery<IGetAvailableJobsResponse, IGetAvailableJobsParams>(
    GET_STUDENT_NOTIFICATION, {
      variables: {
        studentId,
      },
    },
  );
