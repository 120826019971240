import { makeVar, useReactiveVar } from '@apollo/client';

import { getCookie, setCookie } from '../../utils/cookiesUtil';

interface IUseMajorIdCache {
  majorId: string | undefined;
  setMajorId: ( value: string | undefined ) => void;
}

const majorIdCookie = getCookie( 'majorId' )?.toString();

export const majorIdVar = makeVar<string | undefined>( majorIdCookie );

export const useMajorIdCache = (): IUseMajorIdCache => {
  const majorId = useReactiveVar( majorIdVar );

  const setMajorId = ( value: string | undefined ): void => {
    majorIdVar( value );
    setCookie( 'majorId', value ?? '' );
  };

  return {
    majorId,
    setMajorId,
  };
};
