import { IFeatures } from '../contexts/reducer/featureFlagsReducer';
import { ILoginUserSuccessResponse } from '../views/Auth/Login/interfaces/IGoogleUser';

type ICaseHandleAuth = 'jwtTokenAndTokenAndNotExpiredAndnotEmbebedInIframe' | 'embebedInIframe' | 'tokenAndExpiredTokenAndredirectLogoutUrl' | 'embebedInIframeAndPlatform' | 'default' ;

const expiryValidation = ( expireIn: number ) : boolean => {
  const now = Date.now() / 1000;

  return expireIn < now;
};

export const handleCaseAuth = ( args : {
  user?: ILoginUserSuccessResponse,
  token?: string|null,
  features: IFeatures
}): ICaseHandleAuth => {
  const { user, token, features } = args;

  if ( token && user && !expiryValidation( user.exp || 0 ) && !features.embededInIframe ) {
    return 'jwtTokenAndTokenAndNotExpiredAndnotEmbebedInIframe';
  }

  if ( features.embededInIframe ) {
    if ( features.iframeAndPlatform ) {
      return 'embebedInIframeAndPlatform';
    }

    return 'embebedInIframe';
  }

  if ( user && expiryValidation( user.exp || 0 ) && user.redirectLogoutUrl ) {
    return 'tokenAndExpiredTokenAndredirectLogoutUrl';
  }

  return 'default';
};
