import { Theme } from '@mui/material/styles';

import useGetPaletteFromWhiteLabel from '../../hooks/useGetPaletteFromWhiteLabel';
import userAuth from '../../hooks/userAuth';
import { ROLE_ENUM } from '../../views/Auth/Login/interfaces/IGoogleUser';
import palette from '../learnerPalette';

// ----------------------------------------------------------------------

export default function Autocomplete( theme: Theme ): unknown {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;

  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorLearner = wlPalette?.background?.redViolet ?? theme.palette.primary.main;
  const colorAdvisor = palette.light.text.mistBlue;
  const colorPalette = isLearner ? colorLearner : colorAdvisor;

  return {
    MuiAutocomplete: {
      styleOverrides: {
        loading: {
          color: colorPalette,
          fontSize: '1rem',
        },
        paper: {
          boxShadow: theme.customShadows.z20,
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: `${palette.light.background.listSelected} !important`,
          },
          '& .MuiAutocomplete-noOptions': {
            color: colorPalette,
            fontSize: '1rem',
          },
        },
        inputRoot: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colorPalette,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colorPalette,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colorPalette,
          },
        },
      },
    },
  };
}
