import {
  QueryTuple,
  useLazyQuery,
  gql,
  useQuery,
  QueryResult,
} from '@apollo/client';

import { getPolices } from '../../../utils/gqlPolicesUtil';

export const GET_STUDENT_MAJORS_QUERY = gql`
query GetStudentMajors($studentId: NonEmptyString!) {
  getStudentMajors(studentId: $studentId) {
    id
    majorId
    tblMajor {
      id
      name
    }
  }
}
`;

export interface IStudentMajorsParams {
  studentId: string
}

interface IMajor {
  id: string
  name: string
}

export interface IStudentMajors {
  id: number
  studentId: string
  majorId: string
  tblMajor?: IMajor
}

export interface IStudentMajorsResponse {
  getStudentMajors: IStudentMajors[]
}

export const useLazyGetStudentMajors = ( fromCache?: boolean ):
QueryTuple<IStudentMajorsResponse, IStudentMajorsParams> =>
  useLazyQuery<IStudentMajorsResponse, IStudentMajorsParams>(
    GET_STUDENT_MAJORS_QUERY,
    { ...getPolices( fromCache ) },
  );

export const useQueryGetStudentMajors = ( studentId: string, fromCache?: boolean ):
QueryResult<IStudentMajorsResponse, IStudentMajorsParams> =>
  useQuery<IStudentMajorsResponse, IStudentMajorsParams>(
    GET_STUDENT_MAJORS_QUERY, {
      variables: { studentId },
      ...getPolices( fromCache ),
    },
  );
