import {
  ListItemIcon,
  ListItemButton,
  ListItemButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import useGetPaletteFromWhiteLabel from '../../../../hooks/useGetPaletteFromWhiteLabel';
import userAuth from '../../../../hooks/userAuth';
import palette from '../../../../theme/learnerPalette';
import { ROLE_ENUM } from '../../../../views/Auth/Login/interfaces/IGoogleUser';
import { styleConfigs } from '../../../resources/styleConfigs';

export const ListSubheaderStyle = styled( 'div' )(({ theme }) => {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const colorPaletteAdvisor = palette.light.text.ebonyClay;

  const wlPalette = useGetPaletteFromWhiteLabel();
  const colorPaletteWL = wlPalette?.text?.redViolet ?? palette.light.text.ebonyClay;
  const colorPalette = isLearner ? colorPaletteWL : colorPaletteAdvisor;

  return ({
    ...theme.typography.overline,
    color: colorPalette,
    marginTop: styleConfigs.marginPadding.custom40,
    marginBottom: theme.spacing( 2 ),
    paddingLeft: theme.spacing( 5 ),
  });
});

interface IListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

export const ListItemStyle = styled( ListItemButton )<IListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: styleConfigs.dimensions.height48,
  color: theme.palette.text.secondary,
  paddingLeft: theme.spacing( 5 ),
  paddingRight: theme.spacing( 2.5 ),
  position: 'relative',
  '&:focus ': {
    border: styleConfigs.borders.borderLine2px,
    borderColor: palette.light.borders.yaleBlue,
  },
  '&:before': {
    content: '\'\'',
    width: styleConfigs.dimensions.width3,
    top: styleConfigs.marginPadding.empty,
    bottom: styleConfigs.marginPadding.empty,
    right: styleConfigs.marginPadding.empty,
    display: 'none',
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: styleConfigs.borders.border4,
    borderBottomLeftRadius: styleConfigs.borders.border4,
    position: 'absolute',
  },
}));

export const ListItemIconStyle = styled( ListItemIcon )({
  width: styleConfigs.dimensions.width22,
  height: styleConfigs.dimensions.height22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

});
