import { useTheme, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

import { styleConfigs } from '../../../components/resources/styleConfigs';
import AppBarWrapper from '../../../components/wrappers/AppBarWrapper';
import GridWrapper from '../../../components/wrappers/GridWrapper';
import IconButtonWrapper from '../../../components/wrappers/IconButtonWrapper';
import ToolbarWrapper from '../../../components/wrappers/ToolbarWrapper';
import useGetPaletteFromWhiteLabel from '../../../hooks/useGetPaletteFromWhiteLabel';
import userAuth from '../../../hooks/userAuth';
import palette from '../../../theme/learnerPalette';
import { ROLE_ENUM } from '../../../views/Auth/Login/interfaces/IGoogleUser';

export const AppBarStyled = styled( AppBarWrapper )(() => ({
  background: alpha( palette.light.background.alabaster, 0.72 ),
  backdropFilter: 'blur(6px)',
  boxShadow: 'unset',
  [useTheme().breakpoints.up( 'lg' )]: {
    width: `calc(${styleConfigs.dimensions.percent100} - ${styleConfigs.dimensions.width280}px)`,
  },
}));

export const ToolbarStyled = styled( ToolbarWrapper )(() => ({
  height: styleConfigs.dimensions.height64,
  justifyContent: 'space-between',
  [useTheme().breakpoints.up( 'lg' )]: {
    height: styleConfigs.dimensions.height92,
    justifyContent: 'end',
    padding: useTheme().spacing( styleConfigs.marginPadding.empty, styleConfigs.marginPadding.custom5 ),
  },
}));

export const ButtonToolbarStyled = styled( IconButtonWrapper )(() => ({
  fontSize: styleConfigs.fonts.fontSize28,
  color: palette.light.icons.ebonyClay,
  padding: styleConfigs.marginPadding.empty,
  '&:focus ': {
    border: `${styleConfigs.borders.borderLine2px} ${palette.light.borders.yaleBlue}`,
  },
  [useTheme().breakpoints.up( 'lg' )]: {
    display: 'none',
  },
}));

export const GridContainerStyled = styled( GridWrapper )(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: styleConfigs.marginPadding.custom12,
  [useTheme().breakpoints.down( 'sm' )]: {
    gap: styleConfigs.marginPadding.custom4,
  },
}));

export const ListItemIconStyled = styled( 'div' )(() => {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const fillColorPaletteAdvisor = palette.light.text.ebonyClay;

  const wlPalette = useGetPaletteFromWhiteLabel();
  const fillColorPaletteWL = wlPalette?.text?.redViolet ?? palette.light.text.ebonyClay;
  const fillColorPalette = isLearner ? fillColorPaletteWL : fillColorPaletteAdvisor;

  return ({
    width: styleConfigs.dimensions.width24,
    height: styleConfigs.dimensions.height24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `auto ${styleConfigs.marginPadding.custom15}px`,
    color: fillColorPalette,
  });
});

export const ListItemTextStyled = styled( 'div' )(() => {
  const { auth } = userAuth();
  const isLearner: boolean = auth?.userRole === ROLE_ENUM.STUDENT;
  const fillColorPaletteAdvisor = palette.light.text.ebonyClay;

  const wlPalette = useGetPaletteFromWhiteLabel();
  const fillColorPaletteWL = wlPalette?.text?.redViolet ?? palette.light.text.ebonyClay;
  const fillColorPalette = isLearner ? fillColorPaletteWL : fillColorPaletteAdvisor;

  return ({
    color: fillColorPalette,
  });
});
