import './PostMessageSsoConfig';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useAuthErrorMessageHook from '../../../hooks/authErrorMessageHook/useAuthErrorMessageHook';
import { useRegistrationsHook } from '../../../hooks/useRegistrationsHook/useRegistrationsHook';
import { useLoginCustomSSO } from '../../../operations/mutations/public/loginCustomSSO/loginCustomSSO';
import { handleErrorSSo } from '../../../utils/handleErrorsSso';

const PostMessageSso: React.FC = () => {
  const navigate = useNavigate();

  const { setUserAndRedirect } = useRegistrationsHook();
  const [loginCustomSSO] = useLoginCustomSSO();
  const { t } = useTranslation( 'PostMessageSso' );
  const { setAuthErrorMessage } = useAuthErrorMessageHook();

  const handleError = ( message: any ):void => {
    const errorTranslate = handleErrorSSo( message );

    setAuthErrorMessage( t( errorTranslate.translation, { errorCode: errorTranslate.errorCode }));
  };

  useEffect(() => {
    const possibleHosts = [
      'https://webcampus.griky.co',
      'https://experiencia.liderly.com',
      'https://mylearningapp.tec.mx',
    ];

    const currentHost = window.location.origin;

    if ( possibleHosts.includes( currentHost )) {
      window.parent.postMessage( 'ready', currentHost );
    }
  }, []);

  useEffect(() => {
    const handleMessage = ( event: any ): void => {
      if ( event?.data?.token ) {
        const { token } = event.data;

        loginCustomSSO({
          variables: {
            token,
            loginMethod: 'Liderly',
          },
        })
          .then(( response ) => {
            const tokenResponse = response?.data?.loginCustomSSO;

            if ( tokenResponse ) {
              setUserAndRedirect( false, tokenResponse );
            }
          })
          .catch(( err: any ) => {
            handleError( err.message );

            navigate( '/unauthorized' );
          });
      }
      if ( event.data.access_token ) {
        const { access_token } = event.data;

        loginCustomSSO({
          variables: {
            // eslint-disable-next-line camelcase
            token: access_token,
            loginMethod: 'TLG',
          },
        })
          .then(( response ) => {
            const tokenResponse = response?.data?.loginCustomSSO;

            if ( tokenResponse ) {
              setUserAndRedirect( false, tokenResponse );
            }
          })
          .catch(( err: any ) => {
            handleError( err.message );
            navigate( '/unauthorized' );
          });
      }
      if ( event.data.tokenGriky ) {
        const { tokenGriky } = event.data;

        loginCustomSSO({
          variables: {
            token: tokenGriky,
            loginMethod: 'Griky',
          },
        })
          .then(( response ): void => {
            const tokenResponse = response?.data?.loginCustomSSO;

            if ( tokenResponse ) {
              setUserAndRedirect( false, tokenResponse );
            }
          }).catch(( err: any ): void => {
            handleError( err.message );
            navigate( '/unauthorized' );
          });
      }
    };

    window.addEventListener( 'message', handleMessage );

    return () => {
      window.removeEventListener( 'message', handleMessage );
    };
  }, []);

  return null;
};

export default PostMessageSso;
