import axios from 'axios';
import { initializeApp } from 'firebase/app';

import {
  getAuth,
  EmailAuthProvider,
  signInWithCredential,
  UserCredential,
  updateProfile,
  User,
  sendPasswordResetEmail,
  updatePassword,
  verifyPasswordResetCode,
  Auth,
  GoogleAuthProvider,
  signInWithPopup,
  getRedirectResult,
  SAMLAuthProvider,
  OAuthProvider,
} from 'firebase/auth';

import { refreshTokenService } from './auth';
import environmentVariables, { initializeVariables } from '../appVariables';
import firebaseConfig from '../views/Auth/Login/constant/firebase';
import { ILoginUserSuccessResponse } from '../views/Auth/Login/interfaces/IGoogleUser';

const appInitialize = () : Auth => {
  try {
    initializeVariables();
    initializeApp( firebaseConfig );

    return getAuth();
  } catch ( error: any ) {
    throw new Error( error.message );
  }
};

export const loginGooglePopup = async (
  loginProvider?: SAMLAuthProvider,
): Promise<any> => {
  const auth: Auth = appInitialize();
  const provider = loginProvider ?? new GoogleAuthProvider();

  try {
    const user = await signInWithPopup( auth, provider );

    return user;
  } catch ( error: any ) {
    if ( error.message.includes( 'popup-closed-by-user' ) || error.message.includes( 'auth/cancelled-popup-request' )) {
      return null;
    }
    throw new Error( error.message );
  }
};

export const loginMicrosoftPopup = async (
): Promise<any> => {
  const auth: Auth = appInitialize();
  const provider = new OAuthProvider( 'microsoft.com' );

  try {
    const user = await signInWithPopup( auth, provider );

    return user;
  } catch ( error: any ) {
    if ( error.message.includes( 'popup-closed-by-user' )
      || error.message.includes( 'auth/cancelled-popup-request' )) {
      return null;
    }
    throw new Error( error.message );
  }
};

export const loginMicrosoftSingleTenantPopup = async (
): Promise<any> => {
  const auth: Auth = appInitialize();
  const provider = new OAuthProvider( 'microsoft.com' );

  provider.setCustomParameters({
    tenant: environmentVariables?.ssoTenantID ?? '',
  });

  try {
    const user = await signInWithPopup( auth, provider );

    return user;
  } catch ( error: any ) {
    if ( error.message.includes( 'popup-closed-by-user' )
      || error.message.includes( 'auth/cancelled-popup-request' )) {
      return null;
    }
    throw new Error( error.message );
  }
};

export const firebaseAuth = async ( email: string, password: string ) : Promise<UserCredential> => {
  try {
    const auth : Auth = appInitialize();
    const credentials = EmailAuthProvider.credential( email, password );
    const response : UserCredential = await signInWithCredential( auth, credentials );

    return response;
  } catch ( error: any ) {
    throw new Error( error.message );
  }
};

export const firebaseUpdateUser = async () : Promise<boolean> => {
  try {
    const currentUser : UserCredential = await firebaseAuth( 'cnh@ualberta.ca', 'B7BGemedByT4Jydg' );

    await updateProfile(( currentUser.user as User ), {
      displayName: 'Chau Hoang',
    });

    return true;
  } catch ( error: any ) {
    throw new Error( error.message );
  }
};

export const firebaseSendPasswordResetEmail = async ( email: string ) : Promise<boolean> => {
  try {
    initializeApp( firebaseConfig );
    const auth: Auth = getAuth();

    await sendPasswordResetEmail( auth, email );

    return true;
  } catch ( error: any ) {
    throw new Error( error.message );
  }
};

export const changePassword = async ( password: string ) : Promise<boolean> => {
  try {
    const { currentUser } = getAuth();

    if ( !currentUser ) {
      return false;
    }

    await updatePassword( currentUser, password );

    return true;
  } catch ( error: any ) {
    throw new Error( error.message );
  }
};

export const changePasswordVerifyCode = async ( code: string ) : Promise<string> => {
  try {
    const auth : Auth = appInitialize();
    const response: string = await verifyPasswordResetCode( auth, code );

    return response;
  } catch ( error: any ) {
    throw new Error( error.message );
  }
};

export const resetPassword = async ( oobCode: string, newPassword: string, key: string ) : Promise<boolean> => {
  try {
    await axios.post( `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${key}`, {
      oobCode,
      newPassword,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return true;
  } catch ( error: any ) {
    throw new Error( error.message );
  }
};
// 45 min
const DURATION_TOKEN_MILLISECONDS = 45 * 60 * 1000;

export const refreshToken = async ( auth: ILoginUserSuccessResponse ) : Promise<ILoginUserSuccessResponse> => {
  let token : ILoginUserSuccessResponse = auth;

  await axios.post( `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`,
    new URLSearchParams({
      // eslint-disable-next-line camelcase
      grant_type: 'refresh_token',
      // eslint-disable-next-line camelcase
      refresh_token: token.refreshToken,
    })).then( async ({ data }) => {
    if ( data && data.access_token && data.refresh_token ) {
      token = {
        ...token,
        refreshToken: data.refresh_token,
        expiresIn: Date.now() + DURATION_TOKEN_MILLISECONDS,
      };
      if ( auth && auth?.jwtToken ) {
        token = await refreshTokenService( auth?.jwtToken, data.refresh_token );
      }
    }
  });

  return token;
};

export const loginSaml = async ( authProvider: string ): Promise<any> => {
  const samlProvider = new SAMLAuthProvider( authProvider );

  try {
    const user = await loginGooglePopup( samlProvider );

    return user;
  } catch ( error: any ) {
    if ( error.message.includes( 'popup-closed-by-user' ) || error.message.includes( 'auth/cancelled-popup-request' )) {
      return null;
    }

    throw new Error( error.message );
  }
};

const auth: Auth = appInitialize();

export const redirectResultGoogle = async (): Promise<any> => {
  try {
    return await getRedirectResult( auth );
  } catch ( error: any ) {
    throw new Error( error.message );
  }
};
